import React, {memo} from "react";

import PropTypes from "prop-types";
import {
	Col,
	Row,
} from "react-bootstrap";


export const BrowserWarning = memo((props) => {

	return (
		<Row>
			<Col
				xl={{span: "8", offset: "2"}}
			>
				<p
					className={props.className}
				>
					i-Tree and its partners do not endorse any specific
					web-browser, but {props.appName} has been tested to
					work well with modern versions of {props.browserList}.
					Please use the{" "}
					<a href={"https://www.itreetools.org/feedback/"}
						title={"General Feedback"}
						target={"_blank"}
						rel={"noopener noreferrer"}
					>
						Feedback
					</a> form to report issues.
				</p>
			</Col>
		</Row>
	)
});

BrowserWarning.propTypes = {
	className: PropTypes.string,
	appName: PropTypes.string,
	browserList: PropTypes.string,
};