import React, {Component} from "react";


const BenefitsList = (props) => {
	return (
		<ul>
			<li>
				CO<sub>2</sub> Sequestration:{" "}
				{props.values.sequestration}{" "}
				<abbr title={"Kilograms"}>kg</abbr>
			</li>
			<li>
				Avoided Runoff:{" "}
				{props.values.runoff}{" "}
				<abbr title={"Cubic metres"}>m<sup>3</sup></abbr>
			</li>
			<li>
				Air Pollution Removal:{" "}
				{props.values.pollution}{" "}
				<abbr title={"Kilograms"}>kg</abbr>
			</li>
		</ul>
	)
}


export class CommonExplanation extends Component {

	render() {
		let {breakEven, currentBenefits, forecastBenefits} = {...this.props};
		let forecastMaximums = forecastBenefits.getMaximums();
		let current = currentBenefits.getMaximums();
		let locale = "en-GB";
		let options = {
			maximumFractionDigits: 2,
		};

		current.total = current.total.toLocaleString(locale, options);
		current.sequestration = current.sequestration.toLocaleString(locale, options);
		current.sequestrationMonetary = current.sequestrationMonetary.toLocaleString(locale, options);
		current.runoff = current.runoff.toLocaleString(locale, options);
		current.pollution = current.pollution.toLocaleString(locale, options);

		if (breakEven.occurred) {
			for (let field in breakEven.values) {
				breakEven.values[field] = breakEven.values[field].toLocaleString(locale, options);
			}
		} else {
			for (let field in forecastMaximums) {
				forecastMaximums[field] = forecastMaximums[field].toLocaleString(locale, options);
			}
		}


		return (
			<React.Fragment>
				{breakEven.occurred ?
					<p>
						Your project will break even in{" "}
						{breakEven.year}{" "}
						year{breakEven.year > 1 ? "s" : ""}.
					</p>
					:
					<p>
						As you can see, your project will NOT break even.
					</p>
				}
				<span>
					Current total value:{" "}
					&#163;{current.total}
				</span>
				<BenefitsList
					values={current}
				/>
				{breakEven.occurred ?
					<React.Fragment>
					<span>
						Projected project break-even value:{" "}
						&#163;{breakEven.values.total}
					</span>
						<BenefitsList
							values={breakEven.values}
						/>
					</React.Fragment>
					:
					<React.Fragment>
					<span>
						Projected maximum project value:{" "}
						&#163;{forecastMaximums.total}
					</span>
						<BenefitsList
							values={forecastMaximums}
						/>
					</React.Fragment>
				}
			</React.Fragment>
		)
	}
}
