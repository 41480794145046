import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import Papa from "papaparse";

import {Post} from "../../classes";
import Geocode from "react-geocode";


export class MarkerPostSelector extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			filteredMarkers: [],
			noMarkers: false,
			ready: false,
		};

		this.markers = [];
		this.road = "";
		this.type = "";
		this.category = "A";
	}

	loadMarkers = (category = "A") => {
		document.body.style.cursor = "wait";
		let cursor = "default";
		// TODO: Explore option of using two files rather than one.
		// let path = `data/Markers_Join_Minimal_${category}.csv`;
		let path = `data/Markers_Join_Minimal.csv`;
		let markers = [];

		Papa.parse(
			path,
			{
				header: true,
				download: true,
				skipEmptyLines: true,
				dynamicTyping: true,
				chunk: (chunk) => {
					chunk.data.forEach((result, idx) => {
						markers.push(result);
					});
				},
				error: (error) => {
					console.log(error);
					document.body.style.cursor = cursor;
				},
				complete: (results, file) => {
					this.setState({
							ready: true,
						},
						() => {
							this.markers = markers;
							document.body.style.cursor = cursor;
						}
					);
				},
			}
		);
	};

	componentDidMount() {
		this.loadMarkers(this.props.category);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let {road, type, category} = {...this.props};
		let markers = [...this.markers];

		if (road !== "" && type !== "") {
			if (
				(category !== this.category) ||
				(road !== this.road) ||
				(type !== this.type)
			) {
				markers = markers.filter(
					marker => marker.REFERENCE.includes(`${road} ${type}`)
				);

				this.setState({
						noMarkers: markers.length <= 0,
						filteredMarkers: markers,
					},
					() => {
						this.road = road;
						this.type = type;
						this.category = category;
					}
				);
			}
		} else if (this.state.filteredMarkers.length > 0) {
			this.setState({
				filteredMarkers: [],
			});
		}
	}

	selectMarker = async (selection) => {
		let markers = [...this.state.filteredMarkers];
		let pick = markers.filter(
			marker => marker.OBJECTID.toString() === selection
		);
		let marker = new Post(pick[0]);
		let address = "";
		try {
			let response = await Geocode.fromLatLng(marker.lat, marker.lng);
			if (response.status === "OK") {
				address = response.results[0].formatted_address;
			}
		} catch (e) {
			console.log(
				"Likely, the geocoding service not is available due to key not" +
				"being configured."
			);
		}

		this.props.updateProject(
			{
				location: {
					center: marker.coordinates,
					address: address,
					post: marker,
				},
			}
		)
	};

	render() {
		return (
			this.state.ready ?
				<div>
					<p className={"font-italic"}>
						Select a location from the list to begin.
					</p>
					<strong>Marker Post</strong>
					<select
						id={"marker-post-selector"}
						name={"marker-posts"}
						className={"form-control"}
						required
						value={this.props.marker || ""}
						onChange={
							(e) => {
								this.selectMarker(
									e.currentTarget.value
								);
							}
						}
					>
						<option
							disabled
							value={""}
						>
							Select a Marker Post
						</option>
						{this.state.filteredMarkers.length ?
							this.state.filteredMarkers.map((marker, idx) => {
								return (
									<option
										key={marker.OBJECTID}
										value={marker.OBJECTID}
									>
										{marker.REFERENCE}
									</option>
								)
							})
							:
							null
						}
					</select>
					{this.state.noMarkers ?
						<p className={"text-danger font-italic"}>
							There are no markers
							matching the selections.
						</p>
						:
						null
					}
				</div>
				:
				<h5>Loading...</h5>
		)
	}
}

MarkerPostSelector.defaultProps = {
	road: "",
	type: "",
};

MarkerPostSelector.propTypes = {
	road: PropTypes.string,
	type: PropTypes.string,
	updateProject: PropTypes.func,
};
