import React, {Component} from "react";
import {
	Form,
	Row,
	Col,
	Button,
} from "react-bootstrap";

import {SpeciesSelector} from "./SpeciesSelector";

import "../../styles/species-selector.scss";


export class TreePlantingGroupCard extends Component {
	constructor(props) {
		super(props);

		this.conditions = [
			"Excellent",
			"Good",
			"Fair",
			"Poor",
			"Critical",
			"Dead",
			"Dying",
		];
	}

	updateSpecies = (species) => {
		this.props.updatePlanting(
			this.props.planting.uuid,
			"species",
			species
		);
	};

	updateSpeciesInputValidity = (truth) => {
		let feedback = document.querySelector("#species .invalid-feedback").classList;
		let token = "d-block";
		truth ? feedback.remove(token) : feedback.add(token);
	};

	singleLineString = (strings) => {
		let lines = strings.split(/(?:\r\n|\n|\r)/);

		return lines.map((line) => {
			return line.replace(/^\s+/gm, "");
		}).join(" ").trim();
	};

	render() {
		let {idx, disabled, planting} = {...this.props};
		let species = disabled ? "" : planting.species;
		let dbh = disabled ? "" : planting.dbh;
		let condition = disabled ? "" : planting.condition;
		let quantity = disabled ? "" : planting.quantity;

		return (
			<React.Fragment>
				<Row>
					<Col>
						<strong>
							Tree Group {idx + 1}
						</strong>
					</Col>
					<Col>
						<Button
							disabled={disabled}
							className={"float-right"}
							variant={"outline-danger"}
							type={"button"}
							onClick={
								(e) => {
									this.props.removePlanting(planting.uuid);
								}
							}
						>
							Remove This Group
						</Button>
					</Col>
				</Row>

				<Row>
					<Col
						xs={12}
						sm={6}
					>
						<div id={"species"}>
							<Form.Group controlId="speciesSelector">
								<Form.Label>
									Species
								</Form.Label>
								<SpeciesSelector
									disabled={disabled}
									className={"form-control text-nowrap species-selector"}
									invalidClass={"is-invalid"}
									id={`species-${idx}`}
									placeholder={"Begin typing to search"}
									species={species}
									threshold={1}
									onClick={this.updateSpecies}
									onChange={this.updateSpeciesInputValidity}
								/>
								<Form.Control.Feedback type={"invalid"}>
									Please select a species to proceed.
								</Form.Control.Feedback>
							</Form.Group>
						</div>
					</Col>
					<Col>
						<Form.Group controlId="dbhInput">
							<Form.Label>
								<abbr title={"Diameter at breast height"}>
									DBH (<abbr title={"Centimeters"}>cm</abbr>)
								</abbr>
							</Form.Label>
							<Form.Control
								disabled={disabled === true}
								type="number"
								placeholder="Enter a number"
								value={dbh}
								step={"0.01"}
								onChange={
									(e) => {

										this.props.updatePlanting(
											planting.uuid,
											"dbh",
											parseFloat(e.currentTarget.value) || ""
										)
									}
								}
								onFocus={
									(e) => {
										e.currentTarget.select();
										this.props.resetValidity(e);
									}
								}
							/>
							<Form.Control.Feedback type={"invalid"}>
								A{" "}
								<abbr title={"Diameter at breast height"}>
									DBH
								</abbr> is required to proceed.
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col
						xs={12}
						sm={6}
					>
						<Form.Group controlId="conditionSelector">
							<Form.Label>
								Health Condition
							</Form.Label>
							<Form.Control
								disabled={disabled}
								as={"select"}
								required
								placeholder={"Select a health condition"}
								value={condition}
								onChange={
									(e) => this.props.updatePlanting(
										planting.uuid,
										"condition",
										e.currentTarget.value
									)
								}
								onFocus={
									(e) => this.props.resetValidity(e)
								}
							>
								<option
									disabled
									value={""}
								>
									Select a Health Condition
								</option>
								{this.conditions.map((option, idx) => {
									return (
										<option
											key={idx}
											value={option}
										>
											{option}
										</option>
									)
								})}
							</Form.Control>
							<Form.Control.Feedback type={"invalid"}>
								Please select a condition to proceed.
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="quantityInput">
							<Form.Label>
								Number to be Planted
							</Form.Label>
							<Form.Control
								disabled={disabled}
								type={"number"}
								placeholder={"Enter a number"}
								min={"0"}
								max={"9999"}
								value={quantity}
								onChange={
									(e) => this.props.updatePlanting(
										planting.uuid,
										"quantity",
										parseInt(e.currentTarget.value) || ""
									)
								}
								onFocus={
									(e) => {
										e.currentTarget.select();
										this.props.resetValidity(e);
									}
								}
							/>
							<Form.Control.Feedback type={"invalid"}>
								Please enter a positive number to proceed.
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>

			</React.Fragment>
		)
	}
}