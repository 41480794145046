import React from "react";
import {Card} from "react-bootstrap";


export const CardFooter = () => {
	return (
		<Card.Footer>
			<p>
				Use of this tool indicates acceptance of
				the <a href={"https://www.itreetools.org/resources/content/iTree_End_Users_License_Agreement.pdf"}>
				<abbr title={"End User License Agreement"}>EULA</abbr>
			</a>.
			</p>
		</Card.Footer>
	)
};