import React, {Component} from "react";
import {Col} from "react-bootstrap";
import {CommonExplanation} from "./CommonExplanation";


export class MortalityExplanation extends Component {

	render() {
		return (
			<Col>
				<p className={"font-italic"}>
					Adjusting the mortality of trees
					planted in each Tree Planting Group.
				</p>
				<strong>
					Here is a break-even estimation
					with a mortality rate of{" "}
					{this.props.mortality}%:
				</strong>
				<CommonExplanation
					breakEven={this.props.breakEven}
					currentBenefits={this.props.currentBenefits}
					forecastBenefits={this.props.forecastBenefits}
				/>
			</Col>
		)
	}
}
