import React, {memo} from "react";

import {
	Col,
	Row,
} from "react-bootstrap";


export const Introduction = memo((props) => {

	return (
		<React.Fragment>
			<Row>
				<Col>
					<p className={"h3"}>
						An area-based, forecasting
						Ecosystem Services and
						Biodiversity Unit Tool.
					</p>
				</Col>
			</Row>
			<Row className={"mb-3"}>
				<Col>
					<p className={"h6"}>
						Calculate and compare the break-even point
						between ecosystem services and biodiversity
						units lost and gained due to highway
						construction projects.
					</p>
					<p className={"h6"}>
						This is an area-based forecasting Ecosystem
						Services and Biodiversity Unit tool. For
						individual tree calculations, please
						visit{" "}
						<a
							href={"https://ukhe-design.itreetools.org"}
							target={"_blank"}
							rel={"noopener noreferrer"}
						>
							i-Tree Design for Highways England
						</a>
					</p>
				</Col>
			</Row>
		</React.Fragment>
	)
});
