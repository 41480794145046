import React, {Component} from "react";

import {
	Route,
	Switch
} from "react-router-dom";

import "../src/styles/navbar.scss";

import {Alert} from "react-bootstrap";

import Header from "./components/Header";
import {LandingPage} from "./components/LandingPage/LandingPage";
import {MapPageContainer} from "./components/MapPage/MapPageContainer";
import {ReportPageContainer} from "./components/ReportPage/ReportPageContainer";
import {LoadingModal} from "./components/LoadingModal";
import {ErrorModal} from "./components/ErrorModal";
import {ResetPage} from "./components/ResetPage";

import {Project} from "./classes";
import {StorageHandler} from "./classes/storage";

import {
	cloneDeep,
	merge,
} from "lodash";


export class App extends Component {
	constructor(props) {
		super(props);

		this.store = new StorageHandler(process.env.REACT_APP_STATE);

		let state = this.store.getFromStorage();
		let project = state ? new Project(state) : new Project();

		this.state = {
			loading: false,
			error: {
				status: false,
				message: "",
			},
			project: project,
		};

		window.onpopstate = (event) => {
			window.scrollTo({top: 0});
		};
	}

	componentDidMount() {
		let project = cloneDeep(this.state.project);
		let creationDate = Date.parse(project.generated);
		let currentDate = Date.now();
		let week = 604800000;
		let difference = currentDate - creationDate;

		if (difference >= week) {
			let url = "https://www.itreetools.org/xr.php";

			try {
				fetch(url)
					.then((response) => response.json()
						.then((json) => {
							project.exchange = json.rates.GBP;

							this.setState({
								project,
							});
						})
					);
			} catch (error) {
				alert(
					"Native i-Tree benefits values are in US dollars." +
					" A current exchange rate to pounds sterling could" +
					" not be retrieved; a 3 year average of 0.77407 will" +
					" be used for conversion."
				)
			}
		}
	}

	reset = () => {
		this.store.removeFromStorage();
		this.setState({
			loading: false,
			project: new Project(),
		})
	};

	updateSite = (uuid, field, value) => {
		let project = cloneDeep(this.state.project);
		project.sites[uuid][field] = value;
		this.setState({
			project: project,
		});
	};

	updateProject = (data = null, shouldMerge = true, callback = false) => {
		let project = cloneDeep(this.state.project);

		if (shouldMerge) {
			merge(project, data);
		} else {
			let key = Object.keys(data)[0];
			project[key] = data[key];
		}

		this.setState({
				project: project
			},
			() => {
				this.store.putInStorage(project);
				if (callback) callback();
			}
		)
	};

	updateError = (error, callback = false) => {
		this.setState({
				error: error
			},
			() => {
				if (callback) callback();
			}
		);
	};

	loadProject = (text) => {
		let json = JSON.parse(text);

		let project = new Project(json);
		this.setState({
			project,
		});
	};

	render() {
		return (
			<React.Fragment>
				<Header
					reset={this.reset}
				/>
				{process.env.REACT_APP_BUILD_MODE === "development" ?
					<Alert variant={"danger"}>
						WARNING:
						This is a development site. DO NOT USE.
						The public tool is{" "}
						<a href={"https://ukhe-ecocalc.itreetools.org"}>here</a>.
					</Alert>
					:
					null
				}
				<Switch>
					<Route
						path={"/"}
						exact
						render={
							props =>
								<LandingPage
									{...props}
									location={this.state.project.location}
									loadProject={this.loadProject}
									updateProject={this.updateProject}
								/>
						}
					/>
					<Route
						path={"/map"}
						exact
						render={
							props =>
								<MapPageContainer
									zoom={this.state.project.location.zoom}
									center={this.state.project.location.center}
									markerPosition={this.state.project.location.post.coordinates}
									bounds={this.state.project.bounds}
									sites={{...this.state.project.sites}}
									updateSite={this.updateSite}
									updateProject={this.updateProject}
								/>
						}
					/>
					<Route
						path={"/report"}
						exact
						render={
							props =>
								<ReportPageContainer
									project={this.state.project}
									updateError={this.updateError}
								/>
						}
					/>
					<Route
						path={"/reset"}
						exact
						render={
							props =>
								<ResetPage
									{...props}
									reset={this.reset}
								/>
						}
					/>
				</Switch>
				<LoadingModal
					loading={this.state.loading}
					updateProject={this.updateProject}
				/>
				<ErrorModal
					error={this.state.error}
					updateError={this.updateError}
				/>
			</React.Fragment>
		);
	}
}

export default App;
