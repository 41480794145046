import React, {Component} from "react";

import Plotly from "../../classes/custom-plotly"
import createPlotlyComponent from "react-plotly.js/factory";

import {
	Row,
	Col
} from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";

const Plot = createPlotlyComponent(Plotly);


export class BiodiversityPlot extends Component {
	constructor(props) {
		super(props);

		this.config = {
			displaylogo: false,
			modeBarButtons: [
				[
					"zoom2d",
					"pan2d",
					"resetScale2d",
				]
			],
		};
		let ratio = 1.61803398875;

		this.width = window.innerWidth;
		if (this.width >= 1024) this.width = this.width * .625;
		this.height = this.width / ratio;
		if (this.height > 500) this.height = 500;

		this.data = [
			{
				x: [],
				y: [],
				name: "Pre-Biodiversity Units",
				type: "bar",
				marker: {
					color: "red",
				},
			},
			{
				x: [],
				y: [],
				name: "Post-Biodiversity Units",
				type: "bar",
				marker: {
					color: "green",
				},
			},
		];

		let sites = Object.values(cloneDeep(this.props.sites));

		sites.forEach((site, idx) => {
				let units = site.getBiodiversityUnits();
				if (units.pre > 0 || units.post > 0) {
					this.data[0].x.push(site.description);
					this.data[0].y.push(units.pre);
					this.data[1].x.push(site.description);
					this.data[1].y.push(units.post);
				}
			}
		);
	}

	render() {
		if (this.data[0].y.length && this.data[1].y.length) {
			return (
				<Row>
					<Col className={"text-center"}>
						<h3>
							Pre- and Post-Development Biodiversity Units
						</h3>
						<Plot
							data={this.data}
							layout={
								{
									width: this.width,
									height: this.height,
									barmode: "group",
									xaxis: {
										title: "Developments",
									},
									yaxis: {
										title: {
											text: "Biodiversity Units",
											standoff: 15,
										},
									},
									margin: {
										t: 15,
										r: 80,
										b: 160,
										l: 100,
										autoexpand: false,
									},
									legend: {
										orientation: "h",
										y: -0.1875,
									},
								}
							}
							config={this.config}
						/>
					</Col>
				</Row>
			)
		} else {
			return null;
		}
	}
}
