import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {
	FaTimes,
	FaEdit,
	FaDrawPolygon,
} from "react-icons/fa";

import {DeleteModal} from "../MapPage/DeleteModal";

import cloneDeep from "lodash/cloneDeep";


export class TableRow extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			editingForm: false,
			editingDrawing: false,
			deleteIsOpen: false,
		}
	}

	toggleDeleteModal = () => {
		this.setState((prevState, props) => ({
			deleteIsOpen: !prevState.deleteIsOpen
		}))
	};

	editingDrawing = (e) => {
		this.setState(prevState => ({
				editingDrawing: !prevState.editingDrawing
			}),
			() => {
				this.props.editDrawing(
					this.props.site.uuid,
					this.state.editingDrawing
				);
			}
		)
	};

	render() {
		let site = cloneDeep(this.props.site);
		let description = site.description || "No description was entered.";
		let treeCount = site.getTreeCount();
		let measurement = site.formatArea();
		let plantingCount = treeCount === 0 ? 0 : Object.keys(site.plantings).length;
		let units = site.getBiodiversityUnits(true);

		if (
			!this.props.project &&
			description.length > 25
		) {
			description = `${description.substring(0, 26)}...`;
		}

		return (
			<React.Fragment>
				<tr
					onMouseEnter={
						(e) => {
							this.props.toggleHighlight(
								site.uuid,
								true
							);
						}
					}
					onMouseLeave={
						(e) => {
							this.props.toggleHighlight(
								site.uuid,
								false
							);
						}
					}
				>
					{this.props.project ?
						<React.Fragment>
							<td
								className={"text-center text-danger hoverable table-button"}
								onClick={
									(e) => {
										this.toggleDeleteModal();
									}
								}
								title={"Remove site"}
							>
								<FaTimes/>
							</td>
							<td
								className={`text-center hoverable table-button ${this.state.editingDrawing ? "active" : null}`}
								onClick={
									(e) => {
										this.editingDrawing(e);
									}
								}
								title={"Edit drawing"}
							>
								<FaDrawPolygon/>
							</td>
							<td
								className={`text-center hoverable table-button ${this.state.editingForm ? "active" : null}`}
								onClick={
									(e) => {
										this.props.editForm(
											site.uuid
										);
									}
								}
								title={"Edit form"}
							>
								<FaEdit/>
							</td>
						</React.Fragment>
						:
						null
					}
					<td>{this.props.number}</td>
					<td>{description}</td>
					<td>{measurement}</td>
					<td>{site.preHabitat.envis}</td>
					<td>{site.postHabitat.envis}</td>
					{this.props.project ?
						null
						:
						<React.Fragment>
							<td>
								{units.pre}
							</td>
							<td>
								{units.post}
							</td>
						</React.Fragment>
					}
					{measurement ?
						<td>{plantingCount} | {treeCount}</td>
						:
						<td></td>
					}
				</tr>
				<DeleteModal
					uuid={site.uuid}
					show={this.state.deleteIsOpen}
					removeSite={this.props.removeSite}
					toggleDeleteModal={this.toggleDeleteModal}
				/>
			</React.Fragment>
		)
	}
}

TableRow.defaultProps = {
	counter: 1,
	project: true,
};

TableRow.propTypes = {
	site: PropTypes.object,
	counter: PropTypes.number,
	project: PropTypes.bool,
	toggleHighlight: PropTypes.func,
	removeSite: PropTypes.func,
	editForm: PropTypes.func,
};
