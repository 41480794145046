import React, {PureComponent} from "react";
import {
	Col,
	Form,
	Row
} from "react-bootstrap";


export class Significance extends PureComponent {
	render() {
		return (
			<Row>
				<Col>
					<Form.Group controlId="preDevelopmentSignificance">
						<Form.Label>
							Pre-Project
							Strategic Significance
						</Form.Label>
						<Form.Control
							as={"select"}
							value={this.props.preSignificance}
							onChange={
								(e) => {
									e.currentTarget.classList.remove(
										"is-invalid"
									);
									this.props.updateSite(
										"significance",
										parseFloat(e.currentTarget.value)
									);
								}
							}
						>
							<option value={"-1"}/>
							<option value={"1.15"}>
								High
							</option>
							<option value={"1.1"}>
								Medium
							</option>
							<option value={"1"}>
								Low
							</option>
						</Form.Control>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group controlId="postDevelopmentSignificance">
						<Form.Label>
							Post-Project
							Strategic Significance
						</Form.Label>
						<Form.Control
							as={"select"}
							value={this.props.postSignificance}
							onChange={
								(e) => {
									e.currentTarget.classList.remove(
										"is-invalid"
									);
									this.props.updateSite(
										"significance",
										parseFloat(e.currentTarget.value),
										false
									);
								}
							}
						>
							<option value={"-1"}/>
							<option value={"1.15"}>
								High
							</option>
							<option value={"1.1"}>
								Medium
							</option>
							<option value={"1"}>
								Low
							</option>
						</Form.Control>
					</Form.Group>
				</Col>
			</Row>
		)
	}
}
