import React, {memo} from "react";

import {
	Col,
	Row,
} from "react-bootstrap";
import nationalhighways from "../../img/national_highways_logo.jpg";
import treeconomics from "../../img/treeconomics.png";
import kier from "../../img/kier.png";
import evans from "../../img/evans-associates.png";
import design from "../../img/i-tree_design-logo.png";


export const Logos = memo((props) => {

	return (
		<Row>
			<Col>
				<Row>
					<Col>
						<img
							className="d-block mx-auto mb-3"
							width={260}
							src={nationalhighways}
							alt="National Highways logo"
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<img
							className={"d-block mx-auto mb-3"}
							width={150}
							src={treeconomics}
							alt="Treeconomics"
						/>
						<img
							className={"d-block mx-auto mb-2"}
							width={115}
							src={kier}
							alt="Kier"
						/>
						<img
							className={"d-block mx-auto"}
							width={75}
							src={evans}
							alt="Evans Associates"
						/>
					</Col>
					<Col>
						<em
							className={"d-none d-md-block text-center mt-n4"}
						>
							Powered by
						</em>
						<img
							className={"d-block mx-auto"}
							width={115}
							src={design}
							alt="i-Tree Design"
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	)
});
