export class Bounds {
	constructor(
		{
			mortality = "1",
			multiplier = "2",
		} = {}
	) {
		this.mortality = parseFloat(mortality);
		this.multiplier = parseInt(multiplier);
	}

	getFields = () => {
		return {
			mortality: this.mortality,
			multiplier: this.multiplier,
		}
	};
}
