import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {
	Form,
	Row,
	Col,
} from "react-bootstrap";


export class Adjustment extends PureComponent {

	render() {
		return (
			<Row>
				<Col>
					<Form.Group controlId="projectRevision">
						<Form.Label>
							{this.props.linear ?
								"Post-Project Length (% of original)"
								:
								"Area Adjustment (%)"
							}
						</Form.Label>
						<Form.Control
							type={"number"}
							value={this.props.adjustment ?? "100"}
							step={"1"}
							min={"1"}
							max={"100"}
							onChange={
								(e) => {
									this.props.updateSite(
										"adjustment",
										parseInt(e.currentTarget.value) || 0
									);
								}
							}
							onFocus={
								(e) => {
									e.currentTarget.select();
									this.props.resetValidity(e);
								}
							}
						/>
						<Form.Control.Feedback type={"invalid"}>
							Please enter a number from 1 to 100.
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
		)
	}
}

Adjustment.defaultProps = {
	linear: false,
	adjustment: 100,
};

Adjustment.propTypes = {
	linear: PropTypes.bool,
	adjustment: PropTypes.number,
	updateSite: PropTypes.func,
	resetValidity: PropTypes.func,
};
