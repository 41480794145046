import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {Table} from "react-bootstrap";

import {TableRow} from "./TableRow";

import "../../styles/table.scss";

import cloneDeep from "lodash/cloneDeep";


export class SiteTable extends PureComponent {

	render() {
		let props = cloneDeep(this.props);
		let {sites} = {...props};
		sites = Object.values(sites);

		return (
			<React.Fragment>
				<Table className={props.className} bordered>
					<thead>
					<tr>
						{props.project ?
							<th colSpan={"3"}/>
							:
							null
						}
						<th>Drawing</th>
						<th>Description</th>
						<th>
							Area <abbr title={"Square metres"}>
							(m<sup>2</sup>)</abbr>{" "}
							or Length (<abbr title={"Metres"}>m</abbr>)
						</th>
						<th>Pre-Habitat</th>
						<th>Post-Habitat</th>
						{props.project ?
							null
							:
							<React.Fragment>
								<th>Pre-Biodiversity Units</th>
								<th>Post-Biodiversity Units</th>
							</React.Fragment>
						}
						<th>Tree Groups | Trees</th>
					</tr>
					</thead>
					<tbody>
					{sites.length ?
						sites.map((site, idx) => {
							idx = idx + 1;

							return (
								<TableRow
									site={site}
									editable={site.path !== "undefined"}
									key={site.uuid}
									number={idx}
									project={props.project}
									removeSite={props.removeSite}
									toggleHighlight={props.toggleHighlight}
									editForm={props.editForm}
									editDrawing={props.editDrawing}
								/>
							)
						})
						:
						null
					}
					</tbody>
					{props.project ?
						<tfoot>
						<tr>
							<th
								colSpan={"9"}
								className={"text-center"}
							>
								Trace on the map...
							</th>
						</tr>
						</tfoot>
						:
						null}
				</Table>
			</React.Fragment>
		)
	}
}

SiteTable.defaultProps = {
	project: true,
};

SiteTable.propTypes = {
	sites: PropTypes.object,
	bounds: PropTypes.object,
	project: PropTypes.bool,
	className: PropTypes.string,
	removeTrace: PropTypes.func,
	toggleHighlight: PropTypes.func,
	editDrawing: PropTypes.func,
};
