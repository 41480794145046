import React, {PureComponent} from "react";

import {
	Row,
	Col,
} from "react-bootstrap";

import {PlotContainer} from "./PlotContainer";
import {
	GeneralExplanation,
	MortalityExplanation,
	MultiplierExplanation,
	HardstandingExplanation,
} from "./explanations";


export class BreakEvenEstimation extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			general: false,
			reducedMortality: false,
			increasedMortality: false,
			dividedTrees: false,
			multipliedTrees: false,
		}
	}

	confirmCompletion = (field) => {
		this.setState({
			[field]: true,
		});
	};

	render() {
		let {project} = {...this.props};

		return (
			<React.Fragment>
				<Row>
					<Col
						xs={{span: 12}}
						lg={{span: 4, offset: 0}}
					>
						<span className={"h3"}>
							Break-even Estimation:
						</span>
						<br/>
						<span className={"mt-n2 mb-2"}>
							<small>
								<em>
									(You can{" "}
									<a href={"#/map"}>Go Back</a>{" "}
									to modify your project and re-run
									your project.)
								</em>
							</small>
						</span>
					</Col>
				</Row>
				<Row>
					<PlotContainer
						project={project}
						designation={"general"}
						loadingMessage={"Calculating benefits..."}
						explanation={<GeneralExplanation/>}
						confirmCompletion={this.confirmCompletion}
						updateError={this.props.updateError}
					/>
				</Row>
				{this.state.general ?
					<React.Fragment>
						<Row>
							<Col
								xs={{span: 12}}
								lg={{span: 4, offset: 0}}
							>
						<span className={"h3"}>
							Alternative Break-even Estimations:
						</span>
							</Col>
						</Row>
						<Row>
							<PlotContainer
								project={project}
								designation={"reducedMortality"}
								loadingMessage={
									"Performing calculations with a lower mortality..."
								}
								explanation={<MortalityExplanation/>}
								confirmCompletion={this.confirmCompletion}
								updateError={this.props.updateError}
							/>
						</Row>
					</React.Fragment>
					:
					null
				}
				{this.state.reducedMortality ?
					<Row>
						<PlotContainer
							project={project}
							designation={"increasedMortality"}
							loadingMessage={
								"Performing calculations with a higher mortality..."
							}
							explanation={<MortalityExplanation/>}
							confirmCompletion={this.confirmCompletion}
							updateError={this.props.updateError}
						/>
					</Row>
					:
					null
				}
				{this.state.increasedMortality ?
					<Row>
						<PlotContainer
							project={project}
							designation={"dividedTrees"}
							loadingMessage={
								"Performing calculations with fewer trees..."
							}
							explanation={<MultiplierExplanation/>}
							confirmCompletion={this.confirmCompletion}
							updateError={this.props.updateError}
						/>
					</Row>
					:
					null
				}
				{this.state.dividedTrees ?
					<Row>
						<PlotContainer
							project={project}
							designation={"multipliedTrees"}
							loadingMessage={
								"Performing calculations with more trees..."
							}
							explanation={<MultiplierExplanation/>}
							confirmCompletion={this.confirmCompletion}
							updateError={this.props.updateError}
						/>
					</Row>
					:
					null
				}
			</React.Fragment>
		)
	}
}
