import React, {Component} from "react";

import {
	Row,
	Col,
} from "react-bootstrap";

import nationalhighways from "../../img/national_highways_logo.jpg";
import itree from "../../img/i-tree-logo.png";


export class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			address: "Locating...",
		};
	}

	render() {
		return (
			<React.Fragment>
				<Row>
					<Col
						xs={{span: 12}}
						md={{span: 7}}
					>
						<p className={"h4 blue"}>
							Ecosystem Break-Even Calculator
						</p>
						<p className={"mb-1"}>
							Project Report Generated: {this.props.generated}
						</p>
						<p className={"mb-1"}>
							Location: {this.props.location.post.REFERENCE}
						</p>
						<p className={"mb-1"}>
							Nearby Address: {this.props.location.address}
						</p>
					</Col>
					<Col
						xs={{span: 12}}
						md={{span: 5}}
					>
						<div className={"float-right"}>
							<a
								href={"https://nationalhighways.co.uk/"}
								target={"_blank"}
								rel={"noopener noreferrer"}
							>
								<img
									alt={"National Highways logo"}
									src={nationalhighways}
									className="d-none d-md-inline mr-5"
								/>
							</a>
							<a
								href={"https://www.itreetools.org/"}
								target={"_blank"}
								rel={"noopener noreferrer"}
							>
								<img
									alt="logo"
									src={itree}
									className="d-none d-md-inline"
								/>
							</a>
						</div>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}