import React from "react";
import {
	Col,
	Row,
} from "react-bootstrap";


export const Overview = () => {

	return (
		<Row className={"d-none d-md-block"}>
			<Col>
				<p
					style={{"marginBottom": "5px"}}
				>
					Overview:
				</p>
				<ol>
					<li>Find project location</li>
					<li>
						Draw individual polygons to
						capture project area(s).
					</li>
					<li>Determine the habitat type
						for the project area(s).
					</li>
					<li>Assess the biodiversity
						condition and distinctiveness
						for each project area.
					</li>
					<li>Input tree planting information:
						number and species.
					</li>
					<li>Calculate and view estimated
						break-even points of the current
						habitat vs. the future habitat.
					</li>
					<li>
						When assessing a new project you
						will need to go to "Start Over"
						in the "Project" drop down.
					</li>
				</ol>
			</Col>
		</Row>
	)
};