import React, {PureComponent} from "react";

import {
	Modal,
	Form,
	Button,
	Row,
	Col,
} from "react-bootstrap";

export const DBLOCK = "d-block";


export class BoundsModal extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			invalidMortality: false,
			invalidMultiplier: false,
		};

		this.mortality = this.props.mortality || 2;
		this.multiplier = this.props.multiplier || 1;
	};

	cancel = () => {
		this.props.updateProject(
			{
				bounds: {
					mortality: this.mortality,
					multiplier: this.multiplier,
				}
			}
		);
		this.props.toggleModal(false);
	};

	onChange = (field, target) => {
		let value = target.value;
		if (!isNaN(value)) {
			if (value !== "") value = parseInt(value);
			this.props.updateProject(
				{
					bounds: {
						[field]: value,
					},
				}
			);
		}
	};

	onFocus = (target) => {
		target.classList.remove("is-invalid");
		target.nextSibling.classList.remove(DBLOCK);
		target.select();
	};

	onBlur = (target, field) => {
		if (
			target.checkValidity() &&
			target.value !== ""
		) {
			this.setState({
				[field]: false,
			});
		} else {
			target.classList.add("is-invalid");
			target.nextSibling.classList.add(DBLOCK);
			this.setState({
				[field]: true,
			});
		}
	};

	render() {
		return (
			<Modal
				show={this.props.show}
				centered
				onHide={
					() => this.cancel()
				}
			>
				<Modal.Body>
					<p className={"h4"}>
						Adjust <u>project</u> bounding parameters:
					</p>
					<p>
						These value adjustments are applied
						uniformly to the Tree Planting Groups across
						each project area polygon.
					</p>
					<Form>

						<Row>
							<Col>
								<Form.Group controlId="mortalityRate">
									<Form.Label>Mortality Rate (%)</Form.Label>
									<Form.Control
										type={"number"}
										min={"0"}
										max={"100"}
										maxLength={"3"}
										step={"1"}
										value={this.props.mortality}
										onChange={
											(e) => {
												this.onChange(
													"mortality",
													e.currentTarget
												);
											}
										}
										onFocus={
											(e) => {
												this.onFocus(e.currentTarget);
											}
										}
										onBlur={
											(e) => {
												this.onBlur(
													e.currentTarget,
													"invalidMortality"
												);
											}
										}
									/>
									<Form.Control.Feedback type={"invalid"}>
										Please enter a number between 0 and 100.
									</Form.Control.Feedback>
									<Form.Text className="text-muted">
										Example: use a lower percent to be more
										optimistic about tree survival
									</Form.Text>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="plantingMultiplier">
									<Form.Label>
										Number Planted Multiplier (1 to 10)
									</Form.Label>
									<Form.Control
										type={"number"}
										min={"1"}
										max={"10"}
										maxLength={"2"}
										value={this.props.multiplier}
										onChange={
											(e) => {
												this.onChange(
													"multiplier",
													e.currentTarget
												);
											}
										}
										onFocus={
											(e) => {
												this.onFocus(e.currentTarget);
											}
										}
										onBlur={
											(e) => {
												this.onBlur(
													e.currentTarget,
													"invalidMultiplier",
												);
											}
										}
									/>
									<Form.Control.Feedback type={"invalid"}>
										Please enter a number ranging
										from 1 to 10.
									</Form.Control.Feedback>
									<Form.Text className="text-muted">
										If break-even point is not reached, use
										the multiplier to increase or decrease
										the number of trees to be planted.
									</Form.Text>
								</Form.Group>
							</Col>
						</Row>

						<span
							className={"mr-3 float-right"}
						>
						<Button
							className={"mr-3"}
							variant={"outline-danger"}
							type={"button"}
							onClick={
								() => {
									this.cancel();
								}
							}
						>
							Cancel
						</Button>
						<Button
							disabled={this.state.invalidMortality || this.state.invalidMultiplier}
							variant={"success"}
							type={"button"}
							onClick={
								() => {

									this.props.toggleModal(false);
								}
							}
						>
							OK
						</Button>
						</span>
					</Form>
				</Modal.Body>
			</Modal>
		)
	}
}

BoundsModal.defaultProps = {
	show: false,
	mortality: 1,
	multiplier: 2,
};
