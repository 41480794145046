import {v4} from "uuid";


export class Planting {
	constructor(
		{
			uuid = v4(),
			species = "",
			condition = "Good",
			quantity = 0,
			dbh = 0,
		} = {}
	) {
		this.uuid = uuid;
		this.species = species;
		this.condition = condition;
		this.quantity = quantity;
		this.dbh = dbh;
	};

	getFields = () => {
		return {
			uuid: this.uuid,
			species: this.species,
			condition: this.condition,
			quantity: this.quantity,
			dbh: this.dbh,
		};
	};
}
