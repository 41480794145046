import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {Post} from "../../classes";


export class RoadNumber extends PureComponent {
	constructor(props) {
		super(props);

		this.a_roas = [
			"A1", "A1M", "A2", "A3", "A3M", "A4", "A5", "A6", "A11", "A12",
			"A13", "A14", "A14M", "A19", "A20", "A21", "A23", "A26", "A27",
			"A30", "A31", "A34", "A36", "A38", "A38M", "A40", "A41", "A42",
			"A43", "A45", "A46", "A47", "A49", "A50", "A52", "A55", "A56",
			"A58", "A59", "A61", "A62", "A63", "A64", "A66", "A66M", "A69",
			"A74M", "A120", "A127", "A160", "A162", "A167", "A168", "A174",
			"A180", "A184", "A194M", "A195M", "A249", "A259", "A282", "A303",
			"A312", "A316", "A404", "A404M", "A405", "A414", "A417", "A419",
			"A421", "A423", "A428", "A446", "A449", "A452", "A453", "A456",
			"A483", "A494", "A500", "A516", "A556", "A580", "A585", "A590",
			"A595", "A616", "A627M", "A628", "A663", "A696", "A1033", "A1089",
			"A2070", "A4123", "A5036", "A5103", "A5111", "A5127", "A5148",
			"A6055",
		];

		this.m_roas = [
			"M1", "M2", "M3", "M4", "M5", "M6", "M11", "M18", "M20", "M23",
			"M25", "M26", "M27", "M32", "M40", "M42", "M45", "M48", "M49",
			"M50", "M53", "M54", "M55", "M56", "M57", "M58", "M60", "M61",
			"M62", "M65", "M66", "M67", "M69", "M180", "M181", "M271",
			"M602", "M606", "M621",
		];
	}

	render() {
		let roas = this.props.category === "A" ? this.a_roas : this.m_roas;

		return (
			<div>
				<strong>Road Number</strong>
				<select
					id={"road-category-selector"}
					name={"road-categories"}
					className={"form-control"}
					required
					value={this.props.number}
					onChange={
						(e) => {
							this.props.updateProject(
								{
									location: {
										number: e.currentTarget.value,
										address: "",
										post: new Post(),
									},
								}
							)
						}
					}
				>
					<option
						disabled
						value={""}
					>
						Select a Road Number
					</option>
					{
						roas.map(
							(roa, idx) => {
								return (
									<option
										key={roa}
										value={roa}
									>
										{roa}
									</option>
								)
							})
					}
				</select>
			</div>
		)
	}
}

RoadNumber.defaultProps = {
	road_category: "A",
	number: "",
};

RoadNumber.propTypes = {
	road_category: PropTypes.string,
	number: PropTypes.string,
	updateProject: PropTypes.func,
};
