import React, {Component} from "react";
import {Col} from "react-bootstrap";
import {CommonExplanation} from "./CommonExplanation";


export class GeneralExplanation extends Component {

	render() {
		return (
			<Col>
				{this.props.breakEven.occurred ?
					<span className={"d-flex justify-content-start align-items-top"}>
						At right is the break-even estimate for your tree
						plantings.
					</span>
					:
					<span className={"d-flex justify-content-start align-items-top"}>
						At right is the break-even estimate for your tree
						plantings. As you can see, your project does NOT
						approach break-even. Review the Alternative
						Estimations below. If they also fail to meet your
						needs, modify your project and re-run it.
					</span>
				}
				<CommonExplanation
					breakEven={this.props.breakEven}
					currentBenefits={this.props.currentBenefits}
					forecastBenefits={this.props.forecastBenefits}
				/>
			</Col>
		)
	}
}
