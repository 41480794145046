import Geocode from "react-geocode";

import {Post} from "./post";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API);
Geocode.enableDebug(false);


export class Location {
	constructor(
		{
			// Possible location types: Overbridge = BO, Marker Post = MP, Emergency Telephone = TB
			type = "",
			hemisphere = 1,
			address = "",
			category = "A",
			number = "",
			center = {
				lat: 51.509865,
				lng: -0.118092
			},
			zoom = 18,
			post = new Post(),
		} = {}
	) {
		this.type = type;
		this.hemisphere = hemisphere;
		this.address = address;
		this.nation = "United Kingdom";
		this.category = category;
		this.number = number;
		this.center = center;
		this.zoom = zoom;
		this.post = new Post(post);
	}

	getFields = () => {
		return {
			type: this.type,
			hemisphere: this.hemisphere,
			address: this.address,
			nation: this.nation,
			category: this.category,
			number: this.number,
			center: this.center,
			zoom: this.zoom,
			post: this.post,
		}
	};
}
