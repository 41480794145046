import React, {Component} from "react";

import {
	Container,
	Row,
	Col,
	Button,
} from "react-bootstrap";


export class ResetPage extends Component {

	reset = () => {
		this.props.reset();
		this.props.history.push("/");
	};

	render() {
		return (
			<Container>
				<Row>
					<Col className={"text-center"}>
						Are you sure you want to reset the application?
					</Col>
				</Row>
				<Row>
					<Col
						className={"mt-3"}
						xs={{span: 12}}
						sm={{span: 8, offset: 2}}
						md={{span: 6, offset: 0}}
						lg={{span: 5, offset: 1}}
						xl={{span: 4, offset: 2}}
					>
						<Button
							block
							className={"mx-auto"}
							variant={"outline-danger"}
							onClick={
								() => this.props.history.push("/")
							}
						>
							No, do not reset the application
						</Button>
					</Col>
					<Col
						className={"mt-3"}
						xs={{span: 12}}
						sm={{span: 8, offset: 2}}
						md={{span: 6, offset: 0}}
						lg={{span: 5}}
						xl={{span: 4}}
					>
						<Button
							block
							className={"mx-auto"}
							variant={"warning"}
							onClick={
								() => this.reset()
							}
						>
							Yes, reset the application
						</Button>
					</Col>
				</Row>
			</Container>
		)
	}
}