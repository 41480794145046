import React, {Component} from "react";

import {
	Card,
	Button,
	Row,
	Col,
} from "react-bootstrap";

import {CardFooter} from "./CardFooter";


export class LoadProject extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "Choose file",
		};
	}

	loadProject = () => {
		let file = document.getElementById("inputGroupFile01").files[0];
		let reader = new FileReader();
		reader.onload = (e) => {
			this.props.loadProject(reader.result);
		};
		reader.readAsText(file);
	};

	render() {
		return (
			<Row className={"mb-4"}>
				<Col
					md={{span: 10, offset: 1}}
					xl={{span: 6}}
				>
					<p className={"h3"}>Existing Project</p>
					<Card>
						<Card.Body>
							<p className={"font-italic"}>
								Open a project from a saved file.
							</p>
							<div className="input-group">
								<div className="custom-file">
									<input
										aria-describedby="inputGroupFileAddon01"
										className="custom-file-input"
										id="inputGroupFile01"
										type="file"
										onChange={
											(e) => this.setState({
													name: e.currentTarget.files[0].name
												}
											)
										}
									/>
									<label className="custom-file-label" htmlFor="inputGroupFile01">
										{this.state.name}
									</label>
								</div>
							</div>
							<br/>
							<Button
								className={"d-block mx-auto"}
								variant={"success"}
								size={"lg"}
								onClick={
									(e) => {
										this.loadProject()
									}
								}
							>
								Load Project
							</Button>
						</Card.Body>
						<CardFooter/>
					</Card>
				</Col>
			</Row>
		)
	}
}
