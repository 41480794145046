export class StorageHandler {
	constructor(key = "") {
		this.key = key;
		this.available = this.checkForStorage();
	}

	checkForStorage() {
		try {
			localStorage.setItem("TEST", "Token");
			localStorage.removeItem("TEST");
			return true;
		} catch (e) {
			return false;
		}
	};

	putInStorage(value, key = this.key) {
		if (this.available) localStorage.setItem(key, JSON.stringify(value));
	};

	removeFromStorage(key = this.key) {
		if (this.available) localStorage.removeItem(key);
	};

	updateStorage(key, value) {
		if (this.available) {
			let obj = this.getFromStorage();
			if (obj) {
				obj[key] = value;
				this.putInStorage(obj);
			}
		}
	};

	getFromStorage(key = this.key) {
		if (this.available) return JSON.parse(localStorage.getItem(key));
	};
}
