import React, {PureComponent} from "react";
import {
	Col,
	Form,
	Row
} from "react-bootstrap";


export class Distinctiveness extends PureComponent {
	render() {
		return (
			<Row>
				<Col>
					<Form.Group controlId="preDistinctivenessSelector">
						<Form.Label>
							Pre-Project
							Distinctiveness
						</Form.Label>
						<Form.Control
							as={"select"}
							placeholder={
								"Select a post-development" +
								" biodiversity distinctiveness."
							}
							value={
								this.props.preDistinctiveness
							}
							onChange={
								(e) => {
									e.currentTarget.classList.remove(
										"is-invalid"
									);
									this.props.updateSite(
										"distinctiveness",
										parseFloat(e.currentTarget.value)
									);
								}
							}
						>
							<option value={"-1"}/>
							<option value={"8"}>
								Very High
							</option>
							<option value={"6"}>
								High
							</option>
							<option value={"4"}>
								Medium
							</option>
							<option value={"2"}>
								Low
							</option>
							<option value={"0"}>
								Very Low
							</option>
						</Form.Control>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group controlId="postDistinctivenessSelector">
						<Form.Label>
							Post-Project
							Distinctiveness
						</Form.Label>
						<Form.Control
							as={"select"}
							placeholder={
								"Select a post-development" +
								" biodiversity distinctiveness."
							}
							value={
								this.props.postDistinctiveness
							}
							onChange={
								(e) => {
									e.currentTarget.classList.remove(
										"is-invalid"
									);
									this.props.updateSite(
										"distinctiveness",
										parseFloat(e.currentTarget.value),
										false
									);
								}
							}
						>
							<option value={"-1"}/>
							<option value={"8"}>
								Very High
							</option>
							<option value={"6"}>
								High
							</option>
							<option value={"4"}>
								Medium
							</option>
							<option value={"2"}>
								Low
							</option>
							<option value={"0"}>
								Very Low
							</option>
						</Form.Control>
					</Form.Group>
				</Col>
			</Row>
		)
	}
}
