import React, {PureComponent} from "react";

import {
	Col,
} from "react-bootstrap";

import {BreakEvenPlot} from "./BreakEvenPlot";

import {Benefits} from "../../classes";


export class PlotContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
		};

		this.breakEven = {
			occurred: false,
			year: null,
			values: {
				total: 0,
				sequestration: 0,
				runoff: 0,
				pollution: 0,
			},
		};

		this.currentBenefits = new Benefits();
		this.forecastBenefits = new Benefits();
		this.mortality = null;
		this.multiplier = null
		this.controller = new AbortController();
		this.signal = this.controller.signal;
	}

	componentWillUnmount() {
		let signal = this.signal;
		signal.onabort = () => {
			document.body.style.cursor = "default";
		};
		this.controller.abort();
	}

	componentDidMount() {
		// First, get the project object, the plot designation.
		let {project, designation} = {...this.props};

		// Calculate current benefits:
		this.currentBenefits = this.getENVISBenefits(project);

		// Check if this project only contains grasslands. If true,
		// hitting the Engine API is unneeded and forecast benefits are
		// calculated using ENVIS values. If false, proceed with getting
		// future benefits from the Engine API.
		if (project.nonITreePostHabitatsOnly()) {
			this.forecastBenefits = this.getENVISBenefits(project, false);

			this.setState({
				loaded: true,
			});
		} else {
			// If trees are involved, get the mortality and multiplier values.
			let {mortality, multiplier} = {...project.bounds};

			// Adjust mortality and multiplier for the relevant plots:
			switch (designation) {
				case "general":
					multiplier = 1;
					break
				case "reducedMortality":
					mortality -= 2;
					multiplier = 1;
					break
				case "increasedMortality":
					mortality += 2;
					multiplier = 1;
					break
				case "dividedTrees":
					multiplier = 1 / multiplier;
					break
				case "multipliedTrees":
					// FIXME: Does anything need to be done here?
					break
				default:
					multiplier = 1;
			}

			// Correct mortality to ensure it is value usable by the Engine.
			if (mortality < 0) mortality = 0;
			if (mortality > 100) mortality = 100;

			this.multiplier = multiplier;
			this.mortality = mortality;

			// Declare variables need for fetching the benefits:
			let {sites, location, exchange} = {...project};
			let rate = exchange;
			let years = "100";
			let apiDefault = "-1";
			let hemisphere = "north";
			let timeline = "forwards";
			let vintage = "pre-1950";
			let exposure = "5";
			let zero = "0";
			let distance = "10";
			let length = project.getPlantingCount();
			let cursors = ["wait", "default"];
			let parameters = {
				label: [],
				species: [],
				diameter: [],
				condition: [],
				"tree-count": [],
				// The parameters below are not used in
				// this app but are needed by the Engine.
				height: new Array(length).fill(apiDefault),
				"crown-height": new Array(length).fill(apiDefault),
				"crown-width": new Array(length).fill(apiDefault),
				"crown-exposure": new Array(length).fill(exposure),
				vintage: new Array(length).fill(vintage),
				heated: new Array(length).fill(zero),
				cooled: new Array(length).fill(zero),
				direction: new Array(length).fill(zero),
				distance: new Array(length).fill(distance),
			};

			// Populate the remaining parameters.
			for (let s in sites) {
				let site = sites[s];
				if (
					site.complete &&
					!site.postHabitat.grassland &&
					!site.postHabitat.hardstanding
				) {
					for (let p in site["plantings"]) {
						let planting = site["plantings"][p];
						let count = planting["quantity"] * (multiplier);

						if (count < 1) count = 1;

						parameters.label.push(planting["uuid"]);
						parameters.species.push(planting["species"]);
						parameters.diameter.push(planting["dbh"]);
						parameters.condition.push(planting["condition"]);
						parameters["tree-count"].push(count.toString());
					}
				}
			}

			// Use FormData to send data in request body.
			let data = new FormData();

			// Join arrays into comma separated strings and append to FormData.
			for (let p in parameters) data.append(p, parameters[p].join(","))

			// Appends additional values to the form data object.
			data.append("key", process.env.REACT_APP_ITREE_ENGINE_API);
			data.append("report", "full");
			data.append("location", location.post.LocationID);
			data.append("hemisphere", hemisphere);
			data.append("nation", location.nation);
			data.append("timeline", timeline);
			data.append("years", years);
			data.append("mortality-rate", (mortality / 100).toString());
			data.append("electric-rate", apiDefault);
			data.append("natural-gas-rate", apiDefault);

			// Define signal and a 1-minute timeout for the fetch.
			let controller = new AbortController();
			let signal = controller.signal;
			let timer = project.getTreeCount() * 1000;
			if (timer <= 60000) timer = 600000;
			let timeout = setTimeout(() => controller.abort(), timer);

			// Define an abort callback for fetch failures and timeouts.
			signal.onabort = () => {
				document.body.style.cursor = "default";
				this.props.updateError({
						status: true,
						message: "The request to our server has timed out." +
							" You will be returned to the previous page." +
							" Please click Calculate to try again."
					},
					window.history.back()
				);
			};

			// Determine which URL to use:
			let url;
			if (process.env.REACT_APP_ITREE_ENGINE_URL) {
				url = new URL(process.env.REACT_APP_ITREE_ENGINE_URL);
				// Allow JS URL to handle whether there is a trailing slash.
				url = new URL(url + "v3/benefit/?");
			} else if (process.env.REACT_APP_BUILD_MODE === "development") {
				url = new URL("https://dev.api.itreetools.org/v3/benefit/?");
			} else {
				url = new URL("https://api.itreetools.org/v3/benefit/?");
			}

			// Set cursor to wait:
			document.body.style.cursor = cursors[0];

			// Fetch the forecast benefits:
			fetch(
				`${url.origin}${url.pathname}`,
				{
					signal,
					method: "POST",
					body: data,
				}
			)
				.then((response) => {
					// FIXME: Add handling for bad responses.
					if (response && response.ok) {
						response.json().then((json) => {
							clearTimeout(timeout);

							// If usable JSON is available, start assembling
							// the data for plotting. Otherwise, throw an error
							// and return to the previous page.
							if (json.status === "ok") {
								// Drill down to the needed layer in the data.
								let data = json.data.total.benefits.cumulative;

								// Get benefits from grasslands to add
								// to i-Tree benefits
								let grassland = {
									total: 0,
									storage: 0,
									storageMonetary: 0,
									sequestration: 0,
									sequestrationMonetary: 0,
									pollution: 0,
									runoff: 0,
								};

								Object.values(sites).forEach((site, idx) => {
									if (site.postHabitat.grassland) {
										for (let field in grassland) {
											grassland[field] += (parseFloat(site.postHabitat[field]) * site.adjustedArea());
										}
									}
								});

								// Create an array of the total values,
								// converted to pounds, with grassland benefits
								// added in:
								//FIXME: Why is this a .map when the others are
								// done with .forEach?
								this.forecastBenefits.total = data["total-worth"].map(
									(n) => (n["worth"] * rate) + grassland.total
								);

								// Push specific converted values to
								// respective arrays:
								data["category-worth"].forEach(
									(datum, idx) => {
										this.forecastBenefits.sequestration.push(
											(datum.carbon * rate) + grassland.sequestration
										);
										this.forecastBenefits.sequestrationMonetary.push(
											(datum.carbon * rate) + grassland.sequestrationMonetary
										);
										this.forecastBenefits.runoff.push(
											(datum.hydrology * rate) + grassland.runoff
										);
										this.forecastBenefits.pollution.push(
											(datum["pollution-removed"] * rate) + grassland.pollution
										);
									}
								);

								// Determine the break point if it exists, and
								// assign related values if it does.
								this.forecastBenefits.total.forEach((value, idx) => {
									if (value >= this.currentBenefits.total[0]) {
										this.breakEven.occurred = true;
										if (
											this.breakEven.occurred &&
											!this.breakEven.year
										) {
											this.breakEven.year = idx;
											this.breakEven.values.total = value;
											this.breakEven.values.sequestration = this.forecastBenefits.sequestration[idx];
											this.breakEven.values.sequestrationMonetary = this.forecastBenefits.sequestrationMonetary[idx];
											this.breakEven.values.runoff = this.forecastBenefits.runoff[idx];
											this.breakEven.values.pollution = this.forecastBenefits.pollution[idx];
										}
									}
								});

								// Confirm that this dataset has been loaded.
								this.setState({
										loaded: true,
									},
									() => {
										// Reset cursor to default.
										document.body.style.cursor = cursors[1];
										// Confirm that this plot is complete.
										this.props.confirmCompletion(
											designation
										);
									}
								);
							} else if (json.status === "error") {
								this.props.updateError({
										status: true,
										message: `The server has returned the
									 following error: ${json.message} You
									  will be returned to the previous
									   page. Please click Calculate to
									    try again.`
									},
									window.history.back()
								);
							}
						});
					} else {
						this.props.updateError({
								status: true,
								message: `The server has returned
								 status ${response.status} with the
								  message ${response.statusText}. You
								   will be returned to the previous
								    page. Please click Calculate to try
								     again.`
							},
							window.history.back()
						);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	getENVISBenefits = (project, pre = true) => {
		let sites = {...project.sites};
		let fields = {
			total: 0,
			sequestration: 0,
			sequestrationMonetary: 0,
			runoff: 0,
			pollution: 0,
		};

		for (let t in sites) {
			let site = sites[t];

			if (site.complete) {
				let habitat = pre ? site.preHabitat : site.postHabitat;
				let area = site.adjustedArea();

				for (let field in fields) {
					if (field in habitat) {
						let value = habitat[field] * area;
						fields[field] += value;
					}
				}
			}
		}

		let benefits = new Benefits();
		for (let field in fields) {
			if (field in benefits) {
				benefits[field] = new Array(100).fill(fields[field]);
			}
		}

		return benefits;
	};

	render() {
		return (
			this.state.loaded ?
				<React.Fragment>
					<Col>
						{React.cloneElement(
							this.props.explanation,
							{
								mortality: this.mortality,
								multiplier: this.multiplier,
								breakEven: this.breakEven,
								currentBenefits: this.currentBenefits,
								forecastBenefits: this.forecastBenefits,
							}
						)}
					</Col>
					<Col>
						<BreakEvenPlot
							currentBenefits={this.currentBenefits}
							forecastBenefits={this.forecastBenefits}
						/>
					</Col>
				</React.Fragment>
				:
				<Col>
					<h3 className={"text-center"}>
						{this.props.loadingMessage}
					</h3>
				</Col>
		)
	}
}
