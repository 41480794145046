export class Habitat {
	constructor(
		{
			envis = "",
			nvc = "",
			code = "",
			grassland = false,
			hardstanding = false,
			total = 0,
			storage = 0,
			storageMonetary = 0,
			sequestration = 0,
			sequestrationMonetary = 0,
			pollution = 0,
			runoff = 0,
		} = {}
	) {
		this.envis = envis;
		this.nvc = nvc;
		this.code = code;
		this.grassland = grassland.toString() === "true";
		this.hardstanding = hardstanding.toString() === "true";
		this.total = parseFloat(total);
		this.storage = parseFloat(storage);
		this.storageMonetary = parseFloat(storageMonetary);
		this.sequestration = parseFloat(sequestration);
		this.sequestrationMonetary = parseFloat(sequestrationMonetary);
		this.pollution = parseFloat(pollution);
		this.runoff = parseFloat(runoff);
	}

	getFields = () => {
		return {
			envis: this.envis,
			nvc: this.nvc,
			code: this.code,
			grassland: this.grassland,
			hardstanding: this.hardstanding,
			total: this.total,
			storage: this.storage,
			storageMonetary: this.storageMonetary,
			sequestration: this.sequestration,
			sequestrationMonetary: this.sequestrationMonetary,
			pollution: this.pollution,
			runoff: this.runoff,
		};
	};
}
