export class Benefits {
	constructor(
		total = [],
		sequestration = [],
		sequestrationMonetary = [],
		runoff = [],
		pollution = [],
	) {
		this.total = total;
		this.sequestration = sequestration;
		this.sequestrationMonetary = sequestrationMonetary;
		this.runoff = runoff;
		this.pollution = pollution;
	}

	getMaximums() {
		return {
			total: Math.max(...this.total),
			sequestration: Math.max(...this.sequestration),
			sequestrationMonetary: Math.max(...this.sequestrationMonetary),
			runoff: Math.max(...this.runoff),
			pollution: Math.max(...this.pollution),
		}
	}
}
