import React, {Component} from "react";
import {
	Row,
	Col
} from "react-bootstrap";
import {TypeTitle} from "../TypeTitle";


export class HardstandingExplanation extends Component {

	render() {
		return (
			<React.Fragment>
				<TypeTitle
					title={"Hardstanding"}
				/>
				<Row>
					<Col>
						<p className={"h3 text-center"}>
							No benefits are available for projects
							consisting solely of hardstanding
							habitats.
						</p>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}
