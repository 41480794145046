import React, {PureComponent} from 'react';
import PropTypes from "prop-types";

import loadGoogleMapsApi from "load-google-maps-api";

import {drawingOptions} from "../../constants";
import {v4} from "uuid";
import cloneDeep from "lodash/cloneDeep";


export class GoogleMap extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			height: window.innerHeight / 1.75,
		};

		this.googleMaps = null;
		this.map = null;
		this.drawingManager = null;
		this.marker = null;
		this.drawings = {};

		window.addEventListener("resize", this.setMapHeight)
	}

	setMapHeight = () => {
		this.setState({
			height: window.innerHeight / 1.75
		});
	};

	componentDidMount() {
		let key = process.env.REACT_APP_GOOGLE_API;
		let el = document.getElementById(this.props.id);

		loadGoogleMapsApi(
			{
				key: key,
				libraries: this.props.libraries,
			}
		).then((googleMaps) => {
			this.googleMaps = googleMaps;
			this.map = new googleMaps.Map(
				el,
				{
					center: this.props.center,
					zoom: this.props.zoom,
					mapTypeId: this.props.type,
					rotateControl: this.props.rotateControl,
					tilt: this.props.tilt,
					disableDefaultUI: this.props.disableUI,
				}
			);
			this.marker = new googleMaps.Marker({
				position: this.props.markerPosition,
				map: this.map,
			});

			let mode = null;

			if (this.props.drawingMode === "polygon") {
				mode = googleMaps.drawing.OverlayType.POLYGON;
			} else if (this.props.drawingMode === "polyline") {
				mode = googleMaps.drawing.OverlayType.POLYLINE;
			}

			this.drawingManager = new googleMaps.drawing.DrawingManager({
				drawingMode: mode,
				drawingControl: this.props.drawingControl,
				drawingControlOptions: {
					position: googleMaps.ControlPosition.TOP_CENTER,
					drawingModes: [
						googleMaps.drawing.OverlayType.POLYGON,
						googleMaps.drawing.OverlayType.POLYLINE,
					],
				},
				map: this.map,
			});

			// Redraws paths that are passed in.
			if (this.props.paths.length) {
				let paths = cloneDeep(this.props.paths);
				paths.forEach((path, idx) => {
					if (path.linear) {
						let polyline = new googleMaps.Polyline({
							path: path.path,
							map: this.map,
						});
						polyline.setOptions(drawingOptions.base);
						polyline.setOptions(drawingOptions.inactive);
						this.drawings[path.uuid] = polyline;
					} else {
						let polygon = new googleMaps.Polygon({
							path: path.path,
							map: this.map,
						});
						polygon.setOptions(drawingOptions.base);
						polygon.setOptions(drawingOptions.inactive);
						this.drawings[path.uuid] = polygon;
					}
				});
			}

			this.drawingManager.setOptions({
				polygonOptions: drawingOptions.active,
				polylineOptions: drawingOptions.active,
			});

			this.googleMaps.event.addListener(
				this.map,
				"dragend",
				() => {
					if (this.props.onDragEnd) {
						let coordinates = this.map.getCenter();
						let center = {
							lat: coordinates.lat(),
							lng: coordinates.lng()
						};

						this.props.onDragEnd(center);
					}
				}
			);

			this.googleMaps.event.addListener(
				this.map,
				"zoom_changed",
				() => {
					if (this.props.onZoomChange) {
						this.props.onZoomChange(this.map.getZoom());
					}
				}
			);

			this.googleMaps.event.addListener(
				this.drawingManager,
				"polygoncomplete",
				(polygon) => {
					polygon.setOptions(drawingOptions.base);
					polygon.setOptions(drawingOptions.inactive);
					polygon.type = "polygon";
					polygon.uuid = v4();
					this.drawings[polygon.uuid] = polygon;
					this.props.onPolygonComplete(polygon);
				}
			);

			this.googleMaps.event.addListener(
				this.drawingManager,
				"polylinecomplete",
				(polyline) => {
					polyline.setOptions(drawingOptions.base);
					polyline.setOptions(drawingOptions.inactive);
					polyline.type = "polyline";
					polyline.uuid = v4();
					this.drawings[polyline.uuid] = polyline;
					this.props.onPolylineComplete(polyline);
				}
			);

			if (this.props.callback) {
				this.props.callback(
					googleMaps,
					this.drawingManager,
					this.drawings,
				);
			}
		}).catch(function (error) {
			console.error(error)
		});
	}

	render() {
		return (
			<section
				id={this.props.id}
				style={
					{
						width: this.props.width,
						height: this.state.height
					}
				}
			/>
		);
	}
}

GoogleMap.defaultProps = {
	center: {
		lat: 51.509865,
		lng: -0.118092
	},
	disableUI: false,
	drawingControl: true,
	drawingMode: false,
	id: "map",
	libraries: [],
	mode: true,
	rotateControl: true,
	tilt: 0,
	type: "roadmap",
	width: "100%",
	zoom: 18,
};

GoogleMap.propTypes = {
	center: PropTypes.object,
	disableUI: PropTypes.bool,
	drawingControl: PropTypes.bool,
	drawingMode: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.bool,
		PropTypes.string,
	]),
	id: PropTypes.string,
	libraries: PropTypes.array,
	width: PropTypes.string,
	rotateControl: PropTypes.bool,
	tilt: PropTypes.number,
	type: PropTypes.string,
	zoom: PropTypes.number,
	mode: PropTypes.bool,
	paths: PropTypes.array,
	callback: PropTypes.func,
	onPolygonComplete: PropTypes.func,
	onPolylineComplete: PropTypes.func,
	onZoomChange: PropTypes.func,
	onDragEnd: PropTypes.func,
};
