import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {
	Modal,
	Form,
	Button,
	Row,
	Col,
} from "react-bootstrap";

import {
	Description,
	Adjustment,
	HabitatSelector,
	Width,
} from "./Inputs"
import {TreePlantingGroupCard} from "./TreePlantingGroupCard";
import {Planting} from "../../classes";
import * as BD from "./Biodiversity";


export class InputModal extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			description: this.props.site.description,
			adjustment: this.props.site.adjustment,
			width: this.props.site.width,
			preHabitat: this.props.site.preHabitat,
			postHabitat: this.props.site.postHabitat,
			preDevelopment: this.props.site.preDevelopment,
			postDevelopment: this.props.site.postDevelopment,
			plantings: this.props.site.plantings,
		}
	}

	updateSite = (field, value) => {
		this.setState({
			[field]: value,
		});
	};

	updateBiodiversityFields = (field, value, pre = true) => {
		let development = pre ? "preDevelopment" : "postDevelopment";
		let superField = {...this.state[development]};

		superField[field] = value;

		this.setState({
			[development]: superField
		});
	};

	updatePlanting = (uuid, field, value) => {
		let plantings = {...this.state.plantings};
		plantings[uuid][field] = value;

		this.setState({
			plantings: plantings,
		});
	};

	addPlanting = () => {
		let plantings = {...this.state.plantings};
		let planting = new Planting();
		plantings[planting.uuid] = planting;

		this.updateSite("plantings", plantings);
	};

	removePlanting = (uuid) => {
		if (Object.keys(this.state.plantings).length >= 2) {
			let plantings = {...this.state.plantings};
			delete plantings[uuid];
			this.updateSite("plantings", plantings);
		}
	};

	validateInputs = () => {
		let proceed = true;
		let fields = [
			...document.querySelectorAll("input"),
			...document.querySelectorAll("select")
		];

		fields.forEach((field, idx) => {
			field.classList.remove("is-invalid");
			if (
				field.disabled === false &&
				(
					!field.checkValidity() ||
					field.value === ""
				)
			) {
				field.classList.add("is-invalid");
				proceed = false;
			}
		});

		if (
			!this.state.postHabitat.grassland &&
			!this.state.postHabitat.hardstanding
		) {
			let species = document.getElementById("species");
			let dbhInput = document.getElementById("dbhInput");
			let dbhValue = parseFloat(dbhInput.value);
			let quantityInput = document.getElementById("quantityInput");
			let qValue = parseFloat(quantityInput.value);

			if (species.querySelector("input").classList.contains("is-invalid")) {
				species.querySelector(".invalid-feedback").classList.add("d-block");
				proceed = false;
			}

			if (
				dbhInput.classList.contains("is-invalid") ||
				dbhValue <= 0 ||
				isNaN(dbhValue)
			) {
				dbhInput.classList.add("is-invalid");
				dbhInput.nextSibling.classList.add("d-block");
				proceed = false;
			}

			if (
				quantityInput.classList.contains("is-invalid") ||
				qValue <= 0 ||
				isNaN(qValue)
			) {
				quantityInput.classList.add("is-invalid");
				quantityInput.nextSibling.classList.add("d-block");
				proceed = false;
			}
		}

		let pre = document.getElementById("preHabitat");
		if (pre.querySelector("input").classList.contains("is-invalid")) {
			pre.nextSibling.classList.add("d-block");
			proceed = false;
		}

		let post = document.getElementById("postHabitat");
		if (post.querySelector("input").classList.contains("is-invalid")) {
			post.nextSibling.classList.add("d-block");
			proceed = false;
		}

		if (proceed) this.props.completeSite({...this.state});
	};

	resetValidity = (e) => {
		let t = e.currentTarget;
		if (t.classList.contains("is-invalid")) {
			t.classList.remove("is-invalid");
			t.nextSibling.classList.remove("d-block");
		}
	};

	closeModal = () => {
		this.props.hideModal("showInputs");
		if (
			this.props.site.description === "" ||
			typeof this.props.site.description === "undefined"
		) {
			this.props.cancelSite();
		}
	};

	render() {
		let postHabitat = {...this.state.postHabitat};
		let disabled = postHabitat.grassland || postHabitat.hardstanding;
		let preDevelopment = {...this.state.preDevelopment};
		let postDevelopment = {...this.state.postDevelopment};
		let plantings = {...this.state.plantings};
		if (plantings) plantings = Object.values(plantings);

		return (
			<Modal
				show={this.props.show}
				centered
				onHide={
					() => this.closeModal()
				}
			>
				<Modal.Body>
					<p className={"h4"}>
						Enter the parameters for
						this <span className={"text-underline"}>project</span>:
					</p>
					<Form>
						<Description
							description={this.state.description}
							updateSite={this.updateSite}
							resetValidity={this.resetValidity}
						/>
						<Adjustment
							adjustment={this.state.adjustment}
							linear={this.props.site.linear}
							updateSite={this.updateSite}
							resetValidity={this.resetValidity}
						/>
						{this.props.site.linear ?
							<Width
								width={this.state.width}
								updateSite={this.updateSite}
								resetValidity={this.resetValidity}
							/>
							:
							null
						}
						<HabitatSelector
							id={"preHabitat"}
							label={"Pre-Project Habitat"}
							habitat={this.state.preHabitat}
							placeholder={"Select a habitat"}
							postHabitat={false}
							updateSite={this.updateSite}
						/>
						<HabitatSelector
							id={"postHabitat"}
							label={"Post-Project Habitat"}
							habitat={this.state.postHabitat}
							placeholder={"Select a habitat"}
							postDevelopment={true}
							updateSite={this.updateSite}
						/>
						<hr/>
						<Row>
							<Col className={"h4"}>
								Biodiversity Parameters (optional)
							</Col>
						</Row>
						<Row>
							<Col className={"mt-n2 mb-2"}>
								<em>
									<small>
										This section should be completed in
										consultation with the Environment Team
									</small>
								</em>
							</Col>
						</Row>
						<BD.Distinctiveness
							preDistinctiveness={preDevelopment.distinctiveness}
							postDistinctiveness={postDevelopment.distinctiveness}
							updateSite={this.updateBiodiversityFields}
						/>
						<BD.Condition
							preCondition={preDevelopment.condition}
							postCondition={postDevelopment.condition}
							updateSite={this.updateBiodiversityFields}
						/>
						<BD.Connectivity
							preConnectivity={preDevelopment.connectivity}
							postConnectivity={postDevelopment.connectivity}
							updateSite={this.updateBiodiversityFields}
						/>
						<BD.Significance
							preSignificance={preDevelopment.significance}
							postSignificance={postDevelopment.significance}
							updateSite={this.updateBiodiversityFields}
						/>
						<BD.Difficulty
							difficulty={postDevelopment.difficulty}
							updateSite={this.updateBiodiversityFields}
						/>
						<BD.Duration
							duration={postDevelopment.duration}
							updateSite={this.updateBiodiversityFields}
						/>
						<BD.Risk
							risk={postDevelopment.risk}
							updateSite={this.updateBiodiversityFields}
						/>
						<Row>
							<Col>
								<hr/>
								<p className={"h4"}>
									Plant groups of trees in this <u>area</u>:
								</p>
								{plantings.map((planting, idx) =>
									<TreePlantingGroupCard
										key={`${planting["uuid"]}_${idx}`}
										idx={idx}
										planting={planting}
										disabled={disabled}
										updatePlanting={this.updatePlanting}
										removePlanting={this.removePlanting}
										resetValidity={this.resetValidity}
									/>
								)
								}
							</Col>
						</Row>

						<Row>
							<Col>
								<Button
									disabled={plantings.length && disabled}
									variant={"primary"}
									type={"button"}
									onClick={
										() => this.addPlanting()
									}
								>
									Add Another Tree Group
								</Button>
							</Col>
						</Row>

						<Row className={"mt-5"}>
							<Col
								xs={6}
								sm={{span: 3, offset: 6}}
							>
								<Button
									block
									className={"mr-3"}
									variant={"outline-danger"}
									type={"button"}
									onClick={
										() => this.closeModal()
									}
								>
									Cancel
								</Button>
							</Col>
							<Col
								xs={6}
								sm={3}
							>
								<Button
									block
									variant={"success"}
									type={"button"}
									onClick={
										() => this.validateInputs()
									}
								>
									OK
								</Button>
							</Col>
						</Row>

					</Form>
				</Modal.Body>
			</Modal>
		)
	}
}

InputModal.defaultProps = {
	show: false,
	site: null,
};

InputModal.propTypes = {
	show: PropTypes.bool,
	site: PropTypes.object,
};
