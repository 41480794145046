import {Location} from "./location";
import {Bounds} from "./bounds";
import {Site} from "./site";
import {Benefits} from "./benefits";

import {v4} from "uuid";


export class Project {
	constructor(
		{
			uuid = v4(),
			generated = new Date().toLocaleDateString(
				"en-GB",
				{
					day: "numeric",
					month: "long",
					year: "numeric",
				}
			),
			exchange = 0.73533, // Average USD to GBP exchange rate from .
			location = new Location(),
			bounds = new Bounds(),
			sites = {},
		} = {}
	) {
		this.uuid = uuid;
		this.generated = generated;
		this.exchange = exchange;
		this.location = new Location(location);
		this.bounds = new Bounds(bounds);
		this.sites = sites;

		if (Object.keys(this.sites).length === 0) {
			// let site = new Site();
			// this.sites[site.uuid] = site;
		} else if (Object.keys(sites).length >= 1) {
			for (let t in sites) {
				let site = new Site(sites[t]);
				this.sites[site.uuid] = site;
			}
		}
	}

	getFields = () => {
		let fields = {
			uuid: this.uuid,
			generated: this.generated,
			location: this.location.getFields(),
			bounds: this.bounds.getFields(),
			sites: {},
		};

		let sites = this.sites;

		for (let t in sites) {
			if (sites[t].measurement) fields.sites[t] = sites[t];
		}

		return fields;
	};

	getPlantingCount() {
		let count = 0;

		Object.values(this.sites).forEach((site, idx) => {
			if (
				site.complete &&
				!site.postHabitat.grassland &&
				!site.postHabitat.hardstanding
			) {
				count += Object.keys(site.plantings).length;
			}
		});

		return count;
	};

	getTreeCount() {
		let count = 0;

		Object.values(this.sites).forEach((site, idx) => {
			if (
				site.complete &&
				!site.postHabitat.grassland &&
				!site.postHabitat.hardstanding
			) {
				Object.values(site.plantings).forEach((planting, idxx) => {
					count += planting.quantity;
				});
			}
		});

		return count;
	};

	// Checks if post-development habitats are grassland.
	nonITreePostHabitatsOnly() {
		let sites = {...this.sites};
		let length = Object.keys(sites).length;
		let count = 0;

		Object.values(sites).forEach((site, idx) => {
			if (
				site.postHabitat.grassland ||
				site.postHabitat.hardstanding
			) count += 1;
		});

		return length === count;
	};

	// Checks if both all pre- and post-development habitats are hardstanding.
	hardstandingOnly() {
		let sites = {...this.sites};
		let length = Object.keys(sites).length;
		let count = 0;

		Object.values(sites).forEach((site, idx) => {
			if (
				site.preHabitat.hardstanding &&
				site.postHabitat.hardstanding
			) count += 1;
		});

		return length === count;
	};

	export = () => {
		let fields = this.getFields();
		fields = JSON.stringify(fields);
		let blob = new Blob([fields]);
		let name = `EcoCalc_${this.generated.replace(/ /g, "_")}.json`;

		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blob, name)
		} else {
			let a = document.createElement("a");
			let url = URL.createObjectURL(blob);

			a.setAttribute("href", url);
			a.setAttribute("download", name);

			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	};

	getENVISBenefits = (pre = true) => {
		let fields = {
			total: 0,
			sequestration: 0,
			sequestrationMonetary: 0,
			runoff: 0,
			pollution: 0,
		};

		for (let t in this.sites) {
			let site = this.sites[t];

			if (site.complete) {
				let habitat = pre ? site.preHabitat : site.postHabitat;
				let area = site.adjustedArea();

				for (let field in fields) {
					if (field in habitat) {
						let value = habitat[field] * area;
						fields[field] += value;
					}
				}
			}
		}

		let benefits = new Benefits();
		for (let field in fields) {
			if (field in benefits) {
				benefits[field] = new Array(100).fill(fields[field]);
			}
		}

		return benefits;
	};
}
