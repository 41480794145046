import React, {Component} from "react";
import PropTypes from "prop-types";

import {
	Modal,
	Button,
} from "react-bootstrap";


export class ErrorModal extends Component {

	cancelError = () => {
		this.props.updateError({
			status: false,
			message: "",
		});
	};

	render() {
		return (
			<Modal
				show={this.props.error.status}
				centered
				onHide={
					() => this.cancelError()
				}
			>
				<Modal.Body>
					<p className={"h4"}>
						{this.props.error.message}
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant={"success"}
						onClick={
							(e) => this.cancelError()
						}
					>
						Ok
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}

ErrorModal.defaultProps = {
	show: false,
};

ErrorModal.propTypes = {
	show: PropTypes.bool,
};
