import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {Link} from "react-router-dom";

import {
	Button,
	Row,
	Col,
} from "react-bootstrap";

import {FaCalculator} from "react-icons/fa";
import {BoundsModal} from "./BoundsModal";


export class ProjectInstructions extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showBounds: false,
		}
	}

	toggleModal = (value) => {
		this.setState({
			showBounds: value,
		});
	}

	render() {
		return (
			<React.Fragment>
				<Row>
					<Col>
						<p className={"h4"}>
							First, start with the map:
						</p>
						<ul className={"pl-3 pl-sm-5"}>
							<li>
								Trace an area of interest;{" "}
								double click to finish each{" "}
								polygon.
							</li>
							<li>
								Each area should represent
								one habitat type.
							</li>
							<li>Areas are listed in the
								table below the map.
							</li>
							<li>
								You may delete a table
								row to redraw the area.
							</li>
							<li>
								Use the edit tool to
								modify form contents.
							</li>
						</ul>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className={"h4"}>
							Next, tell us about each project area:
						</p>
						<ul className={"pl-3 pl-sm-5"}>
							<li>
								Provide a description for your project area.
							</li>
							<li>
								Define the habitat type, its condition and
								distinctiveness pre- and post-development.
							</li>
							<li>
								Enter the number of trees to be planted in{" "}
								<u>at least one</u> Tree Planting Group with
								the following information:
								<ul>
									<li>Species, condition,
										and <abbr title={"Diameter at breast height"}>
											DBH
										</abbr>.
									</li>
									<li>
										Total number of each species planted.
									</li>
								</ul>
							</li>
							<li>
								Multiple Tree Planting Groups can be added.
							</li>
						</ul>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className={"h4"}>
							Then, set bounding parameters:
						</p>
						<ul className={"pl-3 pl-sm-5"}>
							<li>
								These apply across all project areas and
								tree groups and may help fine tune your
								break-even expectations..
							</li>
							<li>
								Adjust mortality rate (%).
							</li>
							<li>
								Enter a multiplier to adjust the number
								of trees planted.
							</li>
						</ul>
					</Col>
				</Row>
				<Row>
					<Col
						xs={{span: 6}}
						sm={{span: 3}}
						lg={{span: 4}}
					>
						<Button
							block
							className={"mb-3"}
							variant={"primary"}
							onClick={
								() => {
									this.toggleModal(true);
								}
							}
						>
							Edit Bounds
						</Button>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className={"h4"}>
							Click Calculate for Results
						</p>
						<ul className={"pl-3 pl-sm-5"}>
							<li>
								You can return to this
								screen to modify your
								project areas.
							</li>
							<li>
								<strong className={"text-danger"}>
									Please be patient while
									we crunch the numbers!
								</strong>{" "}
								There are many, many calculations
								that must be made.
							</li>
							<li>
								It may take you a couple attempts
								to find the break-even results
								you are looking for.
							</li>
							<li>
								For example, you may need to adjust
								the trees planted via
								the Bounding parameters.
							</li>
						</ul>
					</Col>
				</Row>
				<Row>
					<Col
						xs={{span: 6}}
						sm={{span: 3}}
						lg={{span: 4}}
					>
						<Link to={"/report"}>
							<Button
								block
								disabled={(this.props.length < 1)}
								className={"mb-5"}
								variant={"success"}
							>
								<FaCalculator/> Calculate
							</Button>
						</Link>
					</Col>
				</Row>
				<BoundsModal
					show={this.state.showBounds}
					mortality={this.props.bounds.mortality}
					multiplier={this.props.bounds.multiplier}
					toggleModal={this.toggleModal}
					updateProject={this.props.updateProject}
				/>
			</React.Fragment>
		)
	}
}

ProjectInstructions.propTypes = {
	hideModal: PropTypes.func,
	updateProject: PropTypes.func,
};
