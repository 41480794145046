import React, {PureComponent} from "react";
import {
	Col,
	Form,
	Row
} from "react-bootstrap";


export class Connectivity extends PureComponent {
	render() {
		return (
			<Row>
				<Col>
					<Form.Group controlId="preConnectivitySelector">
						<Form.Label>
							Pre-Project Connectivity
						</Form.Label>
						<Form.Control
							as={"select"}
							placeholder={
								"Select a pre-development" +
								" ecological connectivity."
							}
							value={
								this.props.preConnectivity
							}
							onChange={
								(e) => {
									e.currentTarget.classList.remove(
										"is-invalid"
									);
									this.props.updateSite(
										"connectivity",
										parseFloat(e.currentTarget.value)
									);
								}
							}
						>
							<option value={"-1"}/>
							<option value={"1.15"}>
								High / Highly connected
							</option>
							<option value={"1.1"}>
								Medium / Moderately connected
							</option>
							<option value={"1"}>
								Low / Unconnected
							</option>
						</Form.Control>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group controlId="postConnectivitySelector">
						<Form.Label>
							Post-Project Connectivity
						</Form.Label>
						<Form.Control
							as={"select"}
							placeholder={
								"Select a post-development" +
								" ecological connectivity."
							}
							value={
								this.props.postConnectivity
							}
							onChange={
								(e) => {
									e.currentTarget.classList.remove(
										"is-invalid"
									);
									this.props.updateSite(
										"connectivity",
										parseFloat(e.currentTarget.value),
										false
									);
								}
							}
						>
							<option value={"-1"}/>
							<option value={"1.15"}>
								High / Highly connected
							</option>
							<option value={"1.1"}>
								Medium / Moderately connected
							</option>
							<option value={"1"}>
								Low / Unconnected
							</option>
						</Form.Control>
					</Form.Group>
				</Col>
			</Row>
		)
	}
}
