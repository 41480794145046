import React, {PureComponent} from "react";
import {
	Col,
	Form,
	Row
} from "react-bootstrap";


export class Duration extends PureComponent {
	constructor(props) {
		super(props);

		this.multipliers = [
			"1",
			"0.965",
			"0.931",
			"0.899",
			"0.867",
			"0.837",
			"0.808",
			"0.779",
			"0.752",
			"0.726",
			"0.7",
			"0.676",
			"0.652",
			"0.629",
			"0.607",
			"0.586",
			"0.566",
			"0.546",
			"0.527",
			"0.508",
			"0.49",
			"0.473",
			"0.457",
			"0.441",
			"0.425",
			"0.41",
			"0.396",
			"0.382",
			"0.369",
			"0.356",
			"0.343",
			"0.331",
			"0.32",
		]
	};

	render() {
		return (
			<Row>
				<Col>
					<Form.Group controlId="developmentDuration">
						<Form.Label>
							Project Duration in Years
						</Form.Label>
						<Form.Control
							as={"select"}
							value={this.props.duration}
							onChange={
								(e) => {
									e.currentTarget.classList.remove(
										"is-invalid"
									);
									this.props.updateSite(
										"duration",
										parseFloat(e.currentTarget.value),
										false
									);
								}
							}
						>
							<option value={"-1"}/>
							{this.multipliers.map(
								(value, index) => {
									return (
										<option
											key={value}
											value={value}
										>
											{index}
										</option>
									);
								}
							)}
						</Form.Control>
					</Form.Group>
				</Col>
			</Row>
		)
	}
}
