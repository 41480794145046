import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {
	Form,
	Row,
	Col,
} from "react-bootstrap";


export class Description extends PureComponent {

	render() {
		return (
			<Row>
				<Col>
					<Form.Group controlId={"projectDescription"}>
						<Form.Label>
							Project Description
						</Form.Label>
						<Form.Control
							type={"text"}
							maxLength={"140"}
							value={this.props.description}
							placeholder={"Enter a description"}
							onChange={
								(e) => {
									this.props.updateSite(
										"description",
										e.currentTarget.value
									);
								}
							}
							onFocus={
								(e) => {
									e.currentTarget.select();
									this.props.resetValidity(e);
								}
							}
						/>
						<Form.Control.Feedback type={"invalid"}>
							Please enter a description
							in order to proceed.
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
		)
	}
}

Description.defaultProps = {
	description: "",
};

Description.propTypes = {
	description: PropTypes.string,
	updateSite: PropTypes.func,
	resetValidity: PropTypes.func,
};
