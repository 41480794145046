export const LOCALE = "en-GB";
export const MFD = {maximumFractionDigits: 2};
export const drawingOptions = {
	base: {
		strokeOpacity: 1.0,
		strokeWeight: 2,
		fillOpacity: 0.25,
	},
	active: {
		editable: true,
		strokeColor: "#ffea00",
		fillColor: "#ffea00",
	},
	inactive: {
		editable: false,
		strokeColor: "#ffab00",
		fillColor: "#ffab00",
	},
	hover: {
		editable: false,
		strokeColor: "#00ff14",
		fillColor: "#00ff14",
	},
};
export const species = {
	ABAL3: "Silver Fir",
	ABGR: "Giant Fir",
	ABPR: "Noble Fir",
	ACCA: "Field Maple",
	ACPA: "Japanese Maple",
	ACPL: "Norway Maple",
	ACPS: "Sycamore",
	ACSA1: "Silver Maple",
	AEHI: "Horse Chestnut",
	ALCO2: "Italian Alder",
	ALGL: "Alder",
	ALIN: "Grey Alder",
	ARAR: "Monkey Puzzle",
	BEAL1: "Downy Birch",
	BEPE: "Silver Birch",
	BEUT2: "Paper Birch",
	BUSE2: "commonName Box",
	CABE: "Hornbeam",
	CABI: "Indian Bean Tree",
	CASA2: "Sweet Chestnut",
	CEAT: "Atlas Cedar",
	CEDE: "Deodar Cedar",
	CELI: "Lebanon Cedar",
	CHLA2: "Lawson Cypress",
	CHPI: "Sawara Cypress",
	COAU: "Giant Dracaena",
	COAV: "Hazel",
	COBU: "Cotoneaster",
	COSA81: "Dogwood",
	CROX: "Hawthorn",
	CULE: "Leyland Cypress",
	CUMA: "Monterey Cypress",
	CUSE: "Italian Cypress",
	EUEU: "Spindle",
	EUGU: "Eucalyptus",
	FASY: "commonName Beech",
	FASYPU: "Copper Beech",
	FRAN: "Single Leaf Ash",
	FRAN2: "Narrow Leaf Ash",
	FREX: "Ash",
	GIBI: "Ginkgo",
	ILAQ: "Holly",
	JUCO1: "commonName Juniper",
	JUNI: "Black Walnut",
	JURE: "Walnut",
	LAEU: "Hybrid Larch",
	LANO: "Bay Laurel",
	LIST: "Sweetgum",
	LITU: "Tulip Tree",
	MADO: "Apple",
	MAGR: "Magnolia",
	MASY2: "Crab Apple",
	MEGL: "Dawn Redwood",
	NOOB: "Roble Beech",
	NYSY: "Black Tupelo",
	PIAB: "Norway Spruce",
	PICO: "Lodgepole Pine",
	PINI: "Austrian Pine",
	PIRA: "Monterey Pine",
	PISI: "Sitka Spruce",
	PISY: "Scots Pine",
	PLAC: "London Plane",
	POAL: "White Poplar",
	POCA: "Grey Poplar",
	PONI: "Black Poplar",
	POTR10: "Aspen",
	PRAV: "Wild Cherry",
	PRCE: "Plum Cherry",
	PRDO: "Plum",
	PRLA: "Cherry Laurel",
	PRPA: "Bird Cherry",
	PRSA: "Sargent Cherry",
	PRSE2: "Japanese Cherry",
	PRSP2: "Blackthorn",
	PSME: "Douglas Fir",
	PYCO: "Pear",
	QUCE: "Turkey Oak",
	QUHI1: "Lucombe Oak",
	QUIL2: "Holm Oak",
	QUPE: "Sessile Oak",
	QURO: "English Oak",
	QURU: "Red Oak",
	RHCA: "Buckthorn",
	RHFR: "Alder Buckthorn",
	RHSP1: "Rhododendron",
	ROPS: "False Acacia",
	SAAL4: "White Willow",
	SABA1: "Weeping Willow",
	SACA4: "Goat Willow",
	SACI: "Grey Willow",
	SAFR: "Crack Willow",
	SANI4: "Elder",
	SEGI: "Wellingtonia",
	SOAR: "Whitebeam",
	SOAU: "Rowan",
	SOIN: "Swedish Whitebeam",
	SOTO: "Wild Service Tree",
	TABA: "Yew",
	TADI: "Swamp Cypress",
	THOC: "White Cedar",
	THPL: "Western Red Cedar",
	TIAM: "Basswood",
	TICO: "Little Leaf Lime",
	TIEU1: "commonName Lime",
	TIEU2: "Caucasian Lime",
	TIPL: "Large Leaf Lime",
	TITO: "Silver Lime",
	TSHE: "Western Hemlock",
	ULEU: "Gorse",
	ULGL: "Wych Elm",
	ULMI: "Smooth Leaf Elm",
	ULMIAN: "Cornish Elm",
	ULPR: "English Elm",
};
// export const species = [
// 	{code: "ABAL3", commonName: "Silver Fir"},
// 	{code: "ABGR", commonName: "Giant Fir"},
// 	{code: "ABPR", commonName: "Noble Fir"},
// 	{code: "ACCA", commonName: "Field Maple"},
// 	{code: "ACPA", commonName: "Japanese Maple"},
// 	{code: "ACPL", commonName: "Norway Maple"},
// 	{code: "ACPS", commonName: "Sycamore"},
// 	{code: "ACSA1", commonName: "Silver Maple"},
// 	{code: "AEHI", commonName: "Horse Chestnut"},
// 	{code: "ALCO2", commonName: "Italian Alder"},
// 	{code: "ALGL", commonName: "Alder"},
// 	{code: "ALIN", commonName: "Grey Alder"},
// 	{code: "ARAR", commonName: "Monkey Puzzle"},
// 	{code: "BEAL1", commonName: "Downy Birch"},
// 	{code: "BEPE", commonName: "Silver Birch"},
// 	{code: "BEUT2", commonName: "Paper Birch"},
// 	{code: "BUSE2", commonName: "commonName Box"},
// 	{code: "CABE", commonName: "Hornbeam"},
// 	{code: "CABI", commonName: "Indian Bean Tree"},
// 	{code: "CASA2", commonName: "Sweet Chestnut"},
// 	{code: "CEAT", commonName: "Atlas Cedar"},
// 	{code: "CEDE", commonName: "Deodar Cedar"},
// 	{code: "CELI", commonName: "Lebanon Cedar"},
// 	{code: "CHLA2", commonName: "Lawson Cypress"},
// 	{code: "CHPI", commonName: "Sawara Cypress"},
// 	{code: "COAU", commonName: "Giant Dracaena"},
// 	{code: "COAV", commonName: "Hazel"},
// 	{code: "COBU", commonName: "Cotoneaster"},
// 	{code: "COSA81", commonName: "Dogwood"},
// 	{code: "CROX", commonName: "Hawthorn"},
// 	{code: "CULE", commonName: "Leyland Cypress"},
// 	{code: "CUMA", commonName: "Monterey Cypress"},
// 	{code: "CUSE", commonName: "Italian Cypress"},
// 	{code: "EUEU", commonName: "Spindle"},
// 	{code: "EUGU", commonName: "Eucalyptus"},
// 	{code: "FASY", commonName: "commonName Beech"},
// 	{code: "FASYPU", commonName: "Copper Beech"},
// 	{code: "FRAN", commonName: "Single Leaf Ash"},
// 	{code: "FRAN2", commonName: "Narrow Leaf Ash"},
// 	{code: "FREX", commonName: "Ash"},
// 	{code: "GIBI", commonName: "Ginkgo"},
// 	{code: "ILAQ", commonName: "Holly"},
// 	{code: "JUCO1", commonName: "commonName Juniper"},
// 	{code: "JUNI", commonName: "Black Walnut"},
// 	{code: "JURE", commonName: "Walnut"},
// 	{code: "LAEU", commonName: "Hybrid Larch"},
// 	{code: "LANO", commonName: "Bay Laurel"},
// 	{code: "LIST", commonName: "Sweetgum"},
// 	{code: "LITU", commonName: "Tulip Tree"},
// 	{code: "MADO", commonName: "Apple"},
// 	{code: "MAGR", commonName: "Magnolia"},
// 	{code: "MASY2", commonName: "Crab Apple"},
// 	{code: "MEGL", commonName: "Dawn Redwood"},
// 	{code: "NOOB", commonName: "Roble Beech"},
// 	{code: "NYSY", commonName: "Black Tupelo"},
// 	{code: "PIAB", commonName: "Norway Spruce"},
// 	{code: "PICO", commonName: "Lodgepole Pine"},
// 	{code: "PINI", commonName: "Austrian Pine"},
// 	{code: "PIRA", commonName: "Monterey Pine"},
// 	{code: "PISI", commonName: "Sitka Spruce"},
// 	{code: "PISY", commonName: "Scots Pine"},
// 	{code: "PLAC", commonName: "London Plane"},
// 	{code: "POAL", commonName: "White Poplar"},
// 	{code: "POCA", commonName: "Grey Poplar"},
// 	{code: "PONI", commonName: "Black Poplar"},
// 	{code: "POTR10", commonName: "Aspen"},
// 	{code: "PRAV", commonName: "Wild Cherry"},
// 	{code: "PRCE", commonName: "Plum Cherry"},
// 	{code: "PRDO", commonName: "Plum"},
// 	{code: "PRLA", commonName: "Cherry Laurel"},
// 	{code: "PRPA", commonName: "Bird Cherry"},
// 	{code: "PRSA", commonName: "Sargent Cherry"},
// 	{code: "PRSE2", commonName: "Japanese Cherry"},
// 	{code: "PRSP2", commonName: "Blackthorn"},
// 	{code: "PSME", commonName: "Douglas Fir"},
// 	{code: "PYCO", commonName: "Pear"},
// 	{code: "QUCE", commonName: "Turkey Oak"},
// 	{code: "QUHI1", commonName: "Lucombe Oak"},
// 	{code: "QUIL2", commonName: "Holm Oak"},
// 	{code: "QUPE", commonName: "Sessile Oak"},
// 	{code: "QURO", commonName: "English Oak"},
// 	{code: "QURU", commonName: "Red Oak"},
// 	{code: "RHCA", commonName: "Buckthorn"},
// 	{code: "RHFR", commonName: "Alder Buckthorn"},
// 	{code: "RHSP1", commonName: "Rhododendron"},
// 	{code: "ROPS", commonName: "False Acacia"},
// 	{code: "SAAL4", commonName: "White Willow"},
// 	{code: "SABA1", commonName: "Weeping Willow"},
// 	{code: "SACA4", commonName: "Goat Willow"},
// 	{code: "SACI", commonName: "Grey Willow"},
// 	{code: "SAFR", commonName: "Crack Willow"},
// 	{code: "SANI4", commonName: "Elder"},
// 	{code: "SEGI", commonName: "Wellingtonia"},
// 	{code: "SOAR", commonName: "Whitebeam"},
// 	{code: "SOAU", commonName: "Rowan"},
// 	{code: "SOIN", commonName: "Swedish Whitebeam"},
// 	{code: "SOTO", commonName: "Wild Service Tree"},
// 	{code: "TABA", commonName: "Yew"},
// 	{code: "TADI", commonName: "Swamp Cypress"},
// 	{code: "THOC", commonName: "White Cedar"},
// 	{code: "THPL", commonName: "Western Red Cedar"},
// 	{code: "TIAM", commonName: "Basswood"},
// 	{code: "TICO", commonName: "Little Leaf Lime"},
// 	{code: "TIEU1", commonName: "commonName Lime"},
// 	{code: "TIEU2", commonName: "Caucasian Lime"},
// 	{code: "TIPL", commonName: "Large Leaf Lime"},
// 	{code: "TITO", commonName: "Silver Lime"},
// 	{code: "TSHE", commonName: "Western Hemlock"},
// 	{code: "ULEU", commonName: "Gorse"},
// 	{code: "ULGL", commonName: "Wych Elm"},
// 	{code: "ULMI", commonName: "Smooth Leaf Elm"},
// 	{code: "ULMIAN", commonName: "Cornish Elm"},
// 	{code: "ULPR", commonName: "English Elm"},
// ];
export const habitats = [
	{
		group: 0,
		names: {
			"LE2.1": {
				envis: "Woodland Biomass",
				nvc: "Beech Woodland or Oak Dominated Woodland",
			},
			"LE2.4": {
				envis: "Linear Belts of Trees & Shrubs: Woodland-Broadleaf",
				nvc: "Beech Woodland or Oak Dominated Woodland",
			},
		},
		grassland: false,
		hardstanding: false,
		total: 7.34,
		carbon: {
			storage: 259,
			storageMonetary: 63.58,
			sequestration: 25.40,
			sequestrationMonetary: 6.23,
		},
		pollution: 0.59,
		runoff: 0.52,
	},
	{
		group: 1,
		names: {
			"LE2.2": {
				envis: "Woodland Edge",
				nvc: "Beech Woodland or Oak Dominated Woodland",
			},
		},
		grassland: false,
		hardstanding: false,
		total: 5.36,
		carbon: {
			storage: 171.50,
			storageMonetary: 42.04,
			sequestration: 18.30,
			sequestrationMonetary: 4.48,
		},
		pollution: 0.34,
		runoff: 0.54,
	},
	{
		group: 2,
		names: {
			"LE2.3": {
				envis: "High Forest",
				nvc: "Yew Woodland or Pine Woodland",
			},
			"LE2.4": {
				envis: "Linear Belts of Trees & Shrubs: High Forest-Coniferous",
				nvc: "Yew Woodland or Pine Woodland",
			}
		},
		grassland: false,
		hardstanding: false,
		total: 1.19,
		carbon: {
			storage: 64,
			storageValue: 15.71,
			sequestration: 2.38,
			sequestrationMonetary: 0.58,
		},
		pollution: 0.50,
		runoff: 0.11,
	},
	{
		group: 3,
		names: {
			"LE2.7": {
				envis: "Scattered Trees",
				nvc: "Woodland",
			},
		},
		grassland: false,
		hardstanding: false,
		total: 0.61,
		carbon: {
			storage: 23.07,
			storageMonetary: 5.66,
			sequestration: 1.98,
			sequestrationMonetary: 0.49,
		},
		pollution: 0.08,
		runoff: 0.05,
	},
	{
		group: 4,
		names: {
			"LE5.1": {
				envis: "Individual Trees",
				nvc: "Woodland",
			},
		},
		grassland: false,
		hardstanding: false,
		total: 0.15,
		carbon: {
			storage: 5.77,
			storageMonetary: 1.42,
			sequestration: 0.50,
			sequestrationMonetary: 0.12,
		},
		pollution: 0.02,
		runoff: 0.01,
	},
	{
		group: 5,
		names: {
			"LE2.5": {
				envis: "Shrubs & Intermittent Trees",
				nvc: "Woodland",
			},
			"LE3.1": {
				envis: "Amenity Tree & Shrub Planting",
				nvc: "Scrub Communities",
			},
		},
		grassland: false,
		hardstanding: false,
		total: 2.29,
		carbon: {
			storage: 65.07,
			storageMonetary: 15.91,
			sequestration: 7.58,
			sequestrationMonetary: 1.85,
		},
		pollution: 0.12,
		runoff: 0.32,
	},
	{
		group: 6,
		names: {
			"LE2.6": {
				envis: "Shrubs",
				nvc: "Scrub Communities",
			},
			"LE2.8": {
				envis: "Scrub",
				nvc: "Scrub Communities",
			},
			"LE3.2": {
				envis: "Ornamental Shrubs",
				nvc: "Scrub Communities",
			},
			"LE3.4": {
				envis: "Climbers & Trailers",
				nvc: "Scrub Communities",
			},
		},
		grassland: false,
		hardstanding: false,
		total: 1.68,
		carbon: {
			storage: 42,
			storageMonetary: 10.25,
			sequestration: 5.60,
			sequestrationMonetary: 1.37,
		},
		pollution: 0.04,
		runoff: 0.28,
	},
	{
		group: 7,
		names: {
			"LE4.1": {
				envis: "Ornamental Species Hedges",
				nvc: "Scrub Communities",
			},
			"LE4.3": {
				envis: "Native Species Hedgerows",
				nvc: "Scrub Communities",
			},
		},
		grassland: false,
		hardstanding: false,
		total: 7.43,
		carbon: {
			storage: 2.18,
			storageMonetary: 534.37,
			sequestration: 0.03,
			sequestrationMonetary: 7.37,
		},
		pollution: 0.06,
		runoff: 0.00,
	},
	{
		group: 8,
		names: {
			"LE4.4": {
				envis: "Native Hedgerows with Trees",
				nvc: "Scrub Communities"
			},
		},
		grassland: false,
		hardstanding: false,
		total: 8.04,
		carbon: {
			storage: 25.25,
			storageMonetary: 540.04,
			sequestration: 2.01,
			sequestrationMonetary: 7.86,
		},
		pollution: 0.14,
		runoff: 0.05,
	},
	{
		group: 9,
		names: {
			"LE1.1": {
				envis: "Amenity Grass Areas",
				nvc: "Scrub Communities",
			},
			"LE1.3": {
				envis: "Conservation/Species Rich (Or Conservation) Grassland",
				nvc: "Grasslands & Montane Communities",
			},
			"LE1.6": {
				envis: "Open & Swathe",
				nvc: "Grasslands & Montane Communities",
			},
		},
		grassland: true,
		hardstanding: false,
		total: 9.29,
		carbon: {
			storage: 44.44,
			storageValue: 10919.24,
			sequestration: 0.04,
			sequestrationMonetary: 9.24,
		},
		pollution: 0.05,
		runoff: 0,
	},
	{
		group: 10,
		names: {
			"LE1.2": {
				envis: "Grassland & Bulbs",
				nvc: "Scrub Communities",
			},
		},
		grassland: true,
		hardstanding: false,
		total: 9.29,
		carbon: {
			storage: 44.44,
			storageMonetary: 2977.70,
			sequestration: 0.04,
			sequestrationMonetary: 9.24,
		},
		pollution: 0.05,
		runoff: 0,
	},
	{
		group: 11,
		names: {
			"LE1.5": {
				envis: "Heathland & Moorland",
				nvc: "Heath & Mires",
			},
		},
		grassland: true,
		hardstanding: false,
		total: 7.42,
		carbon: {
			storage: 1.22,
			storageMonetary: 300.50,
			sequestration: 0.03,
			sequestrationMonetary: 7.37,
		},
		pollution: 0.05,
		runoff: 0,
	},
	{
		group: 12,
		names: {
			"LE6.1": {
				envis: "Water Bodies & Associated Plants",
				nvc: "Mires",
			},
		},
		grassland: false,
		hardstanding: false,
		total: 23.11,
		carbon: {
			storage: 0.68,
			storageMonetary: 45.56,
			sequestration: 0.09,
			sequestrationMonetary: 23.07,
		},
		pollution: 0.04,
		runoff: 0,
	},
	{
		group: 13,
		names: {
			"LE6.2": {
				envis: "Banks & Ditches",
				nvc: "Mires",
			},
			"LE6.3": {
				envis: "Reed Beds",
				nvc: "Mires",
			},
		},
		grassland: false,
		hardstanding: false,
		total: 23.11,
		carbon: {
			storage: 46.10,
			storageMonetary: 3088.70,
			sequestration: 0.09,
			sequestrationMonetary: 23.07,
		},
		pollution: 0.04,
		runoff: 0,
	},
	{
		group: 14,
		names: {
			"LE6.4": {
				envis: "Marsh & Wet Grassland",
				nvc: "Mires"
			},
		},
		grassland: true,
		hardstanding: false,
		total: 23.11,
		carbon: {
			storage: 46.10,
			storageMonetary: 3088.70,
			sequestration: 0.09,
			sequestrationMonetary: 23.07,
		},
		pollution: 0.04,
		runoff: 0,
	},
	{
		group: 15,
		names: {
			"Hardstanding": {
				envis: "Hardstanding",
				nvc: "Hardstanding"
			},
		},
		grassland: false,
		hardstanding: true,
		total: 0,
		carbon: {
			storage: 0,
			storageMonetary: 0,
			sequestration: 0,
			sequestrationMonetary: 0,
		},
		pollution: 0.00,
		runoff: 0,
	},
];
