import React, {Component} from "react";
import PropTypes from "prop-types";

import {
	Button,
	Card,
	Col,
	Row,
} from "react-bootstrap";

import {CardFooter} from "./CardFooter";

import {RoadCategory} from "./RoadCategory";
import {RoadNumber} from "./RoadNumber";
import {LocationType} from "./LocationType";
import {MarkerPostSelector} from "./MarkerPost";


export class NewProject extends Component {

	render() {
		return (
			<Row className={"d-none d-lg-block"}>
				<Col
					md={{span: 10, offset: 1}}
					xl={{span: 6}}
				>
					<p className={"h3"}>
						New Project
					</p>
					<Card className={"mb-3"}>
						<Card.Body>
							<p className={"font-italic"}>
								These filters must be applied
								to the list of marker posts.
							</p>
							<RoadCategory
								category={this.props.location.category}
								updateProject={this.props.updateProject}
							/>
							<RoadNumber
								category={this.props.location.category}
								number={this.props.location.number}
								updateProject={this.props.updateProject}
							/>
							<LocationType
								type={this.props.location.type}
								updateProject={this.props.updateProject}
							/>

							<hr/>

							<MarkerPostSelector
								category={this.props.location.category}
								road={this.props.location.number}
								type={this.props.location.type}
								marker={this.props.location.post.OBJECTID}
								updateProject={this.props.updateProject}
							/>
							<div>
								<Button
									disabled={
										this.props.location.post.OBJECTID === ""
									}
									className={"d-block mx-auto mt-3"}
									variant={"success"}
									size={"lg"}
									onClick={
										() => this.props.history.push("/map")
									}
								>
									Get Started
								</Button>
							</div>

						</Card.Body>
						<CardFooter/>
					</Card>
				</Col>
			</Row>
		)
	}
}

NewProject.propTypes = {
	project: PropTypes.object,
	updateProject: PropTypes.func,
};
