import {Planting} from "./planting";
import {Habitat} from "./habitat";
import {Development as PreDevelopment, PostDevelopment} from "./development";
import {v4} from "uuid";
import {LOCALE, MFD} from "../constants";


export class Site {
	constructor(
		{
			uuid = v4(),
			path = [],
			description = "",
			measurement = null,
			width = 0,
			adjustment = 100,
			preHabitat = new Habitat(),
			postHabitat = new Habitat(),
			linear = false,
			complete = false,
			plantings = {},
			preDevelopment = new PreDevelopment(),
			postDevelopment = new PostDevelopment(),
		} = {}
	) {
		this.uuid = uuid;
		this.path = path;
		this.description = description;
		this.measurement = parseFloat(measurement);
		this.width = parseFloat(width);
		this.adjustment = parseFloat(adjustment);
		this.preHabitat = new Habitat(preHabitat);
		this.postHabitat = new Habitat(postHabitat);
		this.linear = linear;
		this.complete = complete;
		this.plantings = plantings;
		this.preDevelopment = new PreDevelopment(preDevelopment);
		this.postDevelopment = new PostDevelopment(postDevelopment);

		if (Object.keys(plantings).length === 0) {
			let planting = new Planting();
			this.plantings[planting.uuid] = planting;
		} else if (Object.keys(plantings).length >= 1) {
			for (let p in plantings) {
				let planting = new Planting(plantings[p]);
				this.plantings[planting.uuid] = planting;
			}
		}
	}

	getFields = () => {
		return {
			uuid: this.uuid,
			path: this.path,
			description: this.description,
			measurement: this.measurement,
			width: this.width,
			adjustment: this.adjustment,
			preHabitat: this.preHabitat,
			postHabitat: this.postHabitat,
			linear: this.linear,
			complete: this.complete,
			plantings: this.plantings,
			preDevelopment: this.preDevelopment,
			postDevelopment: this.postDevelopment,
		};
	};

	// Format the area to be human-friendly.
	formatArea() {
		let measurement = null;

		if (this.measurement) {
			measurement = this.adjustedArea();
			measurement = measurement.toLocaleString(LOCALE, MFD);
			measurement = `${measurement}`
		}

		return measurement;
	}

	adjustedArea() {
		return this.measurement * (this.adjustment / 100);
	}

	getTreeCount() {
		let count = 0;

		Object.values(this.plantings).forEach((planting, idx) => {
			if (
				!this.postHabitat.grassland &&
				!this.postHabitat.hardstanding
			) {
				count += planting.quantity;
			}
		});

		return count;
	}

	//FIXME: This doesn't seem to be able to access up-to-date values...
	// Calculate biodiversity units
	getBiodiversityUnits(string = false) {
		const hectares = 10000;
		let measurement;

		if (this.linear) {
			// FIXME: This solution to the "linear area" problem is
			//  most likely temporary and will need to be replaced.
			// TODO:Figure out what type of unit this is; probably meters?
			measurement = (this.measurement * this.width) / hectares;
		} else {
			measurement = this.adjustedArea() / hectares;
		}

		let preD = {...this.preDevelopment};
		let postD = {...this.postDevelopment};

		let preDistinctiveness = preD.distinctiveness > -1 ? preD.distinctiveness : 0;
		let preCondition = preD.condition > -1 ? preD.condition: 0;
		let preConnectivity = preD.connectivity > -1 ? preD.connectivity : 0;
		let preSignificance = preD.significance > -1 ? preD.significance : 0;

		let postDistinctiveness = postD.distinctiveness > -1 ? postD.distinctiveness : 0;
		let postCondition = postD.condition > -1 ? postD.condition : 0;
		let postConnectivity = postD.connectivity > -1 ? postD.connectivity : 0;
		let postSignificance = postD.significance > -1 ? postD.significance : 0;
		let difficulty = postD.difficulty > -1 ? postD.difficulty : 0;
		let duration = postD.duration > -1 ? postD.duration : 0;
		let risk = postD.risk > -1 ? postD.risk : 0;

		let pre = measurement * preDistinctiveness * preCondition * preConnectivity * preSignificance;
		let post = (((((measurement * postDistinctiveness * postCondition) - (measurement * preDistinctiveness * preCondition)) * (difficulty * duration)) + (measurement * preDistinctiveness * preCondition)) * (postConnectivity * postSignificance)) * risk;

		if (string) {
			pre = pre.toLocaleString(LOCALE, MFD);
			post = post.toLocaleString(LOCALE, MFD);
		}

		return {
			pre: pre,
			post: post,
		};
	};
}
