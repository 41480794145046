import React, {PureComponent} from "react";
import {
	Col,
	Form,
	Row
} from "react-bootstrap";


export class Difficulty extends PureComponent {
	render() {
		return (
			<Row>
				<Col>
					<Form.Group controlId="preDevelopmentDifficulty">
						<Form.Label>
							Project Difficulty
						</Form.Label>
						<Form.Control
							as={"select"}
							value={this.props.difficulty}
							onChange={
								(e) => {
									e.currentTarget.classList.remove(
										"is-invalid"
									);
									this.props.updateSite(
										"difficulty",
										parseFloat(e.currentTarget.value),
										false
									);
								}
							}
						>
							<option value={"-1"}/>
							<option value={"0.1"}>
								Very High
							</option>
							<option value={"0.33"}>
								High
							</option>
							<option value={"0.67"}>
								Medium
							</option>
							<option value={"1"}>
								Low
							</option>
						</Form.Control>
					</Form.Group>
				</Col>
			</Row>
		)
	}
}
