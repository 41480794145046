export class Development {
	constructor(
		{
			condition = "",
			distinctiveness = "",
			significance = "",
			connectivity = "",
		} = {}
	) {
		this.condition = condition;
		this.distinctiveness = distinctiveness;
		this.significance = significance;
		this.connectivity = connectivity;
	}

	getFields = () => {
		return {
			condition: this.condition,
			distinctiveness: this.distinctiveness,
			significance: this.significance,
			connectivity: this.connectivity,
		}
	};

	// Calculate biodiversity units
	units() {
		let {
			condition,
			distinctiveness,
			significance,
			connectivity
		} = {...this};

		return condition * distinctiveness * significance * connectivity;
	}
}


export class PostDevelopment extends Development {
	constructor(
		{
			condition = "",
			distinctiveness = "",
			significance = "",
			connectivity = "",
			difficulty = "",
			duration = "",
			risk = "",
		} = {}
	) {
		super(
			{
				condition,
				distinctiveness,
				significance,
				connectivity,
			}
		);
		this.difficulty = difficulty;
		this.duration = duration;
		this.risk = risk;
	}

	// Calculate biodiversity units
	units() {
		let {
			condition,
			distinctiveness,
			significance,
			connectivity,
			difficulty,
			duration,
			risk,
		} = {...this};

		return condition * distinctiveness * significance * connectivity * difficulty * duration * risk;
	}
}
