export class Post {
	constructor(
		{
			OBJECTID = "",
			REFERENCE = "",
			lat = 0,
			lng = 0,
			LocationID = 0,
		} = {}
	) {
		this.OBJECTID = OBJECTID.toString();
		this.REFERENCE = REFERENCE;
		this.lat = lat;
		this.lng = lng;
		this.coordinates = {lat: lat, lng: lng};
		this.LocationID = LocationID;
	}
}
