import React, {PureComponent} from "react";
import {
	Col,
	Form,
	Row
} from "react-bootstrap";


export class Risk extends PureComponent {

	render() {
		return (
			<Row>
				<Col>
					<Form.Group controlId="offisteRisk">
						<Form.Label>
							Off-site Risk
						</Form.Label>
						<Form.Control
							as={"select"}
							value={this.props.risk}
							onChange={
								(e) => {
									e.currentTarget.classList.remove(
										"is-invalid"
									);
									this.props.updateSite(
										"risk",
										parseFloat(e.currentTarget.value),
										false
									);
								}
							}
						>
							<option value={"-1"}/>
							<option value={"1"}>
								Compensation inside LPA or NCA of impact site.
							</option>
							<option value={"0.75"}>
								Compensation outside LPA or NCA of impact site
								but in neighbouring LPA or NCA.
							</option>
							<option value={"0.5"}>
								Compensation outside LPA or NCA of impact site
								and beyond neighbouring LPA or NCA.
							</option>
						</Form.Control>
					</Form.Group>
				</Col>
			</Row>
		)
	}
}
