import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";


export class TypeTitle extends Component {

	render() {
		return (
			<Row>
				<Col
					xs={{span: 12}}
					lg={{span: 4, offset: 0}}
				>
						<span className={"h3"}>
							{this.props.title}:
						</span>
					<br/>
					<span className={"mt-n2 mb-2"}>
							<small>
								<em>
									(You can{" "}
									<a href={"#/map"}>Go Back</a>{" "}
									to modify your project and re-run
									your project.)
								</em>
							</small>
						</span>
				</Col>
			</Row>
		)
	}
}
