import React, {Component} from "react";

import {
	Row,
	Col,
} from "react-bootstrap";

import treeconomics from "../img/treeconomics.png";
import kier from "../img/kier.png";
import evans from "../img/evans-associates.png";
import forest from "../img/partners/forest-service.png";
import davey from "../img/partners/davey-tree.png";


export class Footer extends Component {

	render() {
		return (
			<React.Fragment>
				<Row className={"my-4 text-center"}>
					<Col>
						<img
							height={"40"}
							src={treeconomics}
							alt={"Treeconomics Logo"}
						/>
					</Col>
					<Col>
						<img
							height={"42"}
							src={kier}
							alt={"Kier Logo"}
						/>
					</Col>
					<Col>
						<img
							height={"42"}
							src={evans}
							alt={"Evans Associates Logo"}
						/>
					</Col>
					<Col>
						<img
							height={"42"}
							src={forest}
							alt={"U.S. Forest Service Logo"}
						/>
					</Col>
					<Col>
						<img
							height={"42"}
							src={davey}
							alt={"Davey Tree Logo"}
						/>
					</Col>
				</Row>
				<Row className={"mb-4 text-center"}>
					<Col>
						<p>
							<em>
								Use of this tool indicates acceptance of
								the <a href={"https://www.itreetools.org/resources/content/iTree_End_Users_License_Agreement.pdf"}>
								<abbr title={"End User License Agreement"}>EULA</abbr></a>
							</em>
						</p>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}
