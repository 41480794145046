import React, {Component} from "react";
import PropTypes from "prop-types";

import {
	Modal,
	Button,
} from "react-bootstrap";


export class DeleteModal extends Component {

	render() {
		return (
			<Modal
				show={this.props.show}
				centered
				onHide={
					() => {
						this.props.toggleDeleteModal()
					}
				}
			>
				<Modal.Body>
					<p className={"h4"}>
						Are you sure you want to delete this drawing?
						The drawing itself and all associated data
						will be removed.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant={"outline-danger"}
						onClick={
							(e) => {
								this.props.toggleDeleteModal()
							}
						}
					>
						Cancel
					</Button>
					<Button
						variant={"success"}
						onClick={
							(e) => {
								this.props.removeSite(this.props.uuid)
							}
						}
					>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}

DeleteModal.defaultProps = {
	show: false,
};

DeleteModal.propTypes = {
	show: PropTypes.bool,
};
