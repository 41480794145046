import React, {Component} from "react";
import {
	Container,
	Row,
	Col,
} from "react-bootstrap";

import {GoogleMap} from "../MapPage/GoogleMap";
import {Footer} from "../Footer";

import {Header} from "./Header";
import {SiteTable} from "../SharedComponents/SiteTable";
import {BoundingParameters} from "./BoundingParameters";
import {BreakEvenEstimation} from "./BreakEvenEstimation";
import {BiodiversityPlot} from "./BiodiversityPlot";
import {HardstandingExplanation} from "./explanations";

import cloneDeep from "lodash/cloneDeep";
import {drawingOptions} from "../../constants";


export class ReportPageContainer extends Component {
	constructor(props) {
		super(props);

		this.gm = null;
		this.dm = null;
		this.drawings = {};
		this.paths = [];

		let sites = cloneDeep(this.props.project.sites);
		for (let s in sites) {
			let site = sites[s];
			this.paths.push({
				uuid: site.uuid,
				path: site.path,
				linear: site.linear,
			});
		}
	}

	setMap = (gm, dm, drawings) => {
		this.gm = gm;
		this.dm = dm;
		this.drawings = drawings;
	};

	toggleHighlight = (uuid, truth) => {
		let drawing = this.drawings[uuid];

		// Check for drawing to prevent errors when hovering
		// over the table before the drawings have been redrawn.
		if (drawing) {
			if (truth) {
				drawing.setOptions(drawingOptions.hover);
			} else {
				if (this.editing === uuid) {
					drawing.setOptions(drawingOptions.active);
				} else {
					drawing.setOptions(drawingOptions.inactive);
				}
			}
		}
	};

	render() {
		let project = cloneDeep(this.props.project);
		let hardstandingOnly = project.hardstandingOnly();

		return (
			<Container fluid>
				<Row>
					<Col>
						<Header
							location={project.location}
							generated={project.generated}
						/>
					</Col>
				</Row>
				<Row>
					<Col
						className={"mb-3"}
						xs={{span: 10, offset: 1}}
						sm={{span: 12, offset: 0}}
						lg={{span: 4}}
					>
						<GoogleMap
							zoom={project.location.zoom - 1}
							center={project.location.center}
							markerPosition={project.location.post.coordinates}
							drawingControl={false}
							drawingMode={false}
							height={500}
							libraries={["drawing", "geometry"]}
							id={"reportMap"}
							rotateControl={false}
							tilt={0}
							type={"satellite"}
							paths={this.paths}
							disableUI={true}
							callback={this.setMap}
						/>
					</Col>
					<Col
						xs={{span: 12}}
						lg={{span: 8}}
					>
						<SiteTable
							sites={project.sites}
							bounds={project.bounds}
							project={false}
							toggleHighlight={this.toggleHighlight}
						/>
					</Col>
				</Row>
				<Row>
					<Col
						xs={{span: 12}}
						lg={{span: 4}}
					>
						<BoundingParameters
							count={project.getTreeCount()}
							bounds={project.bounds}
						/>
					</Col>
				</Row>
				<hr/>
				{hardstandingOnly ?
					<HardstandingExplanation/>
					:
					<React.Fragment>
						<BreakEvenEstimation
							project={project}
							updateError={this.props.updateError}
						/>
						<hr/>
						<BiodiversityPlot
							sites={project.sites}
						/>
					</React.Fragment>
				}
				{window.location.hostname === "localhost" ?
					<button
						onClick={() => project.export()}
					>
						Save project
					</button>
					:
					null
				}
				<Footer/>
			</Container>
		)
	}
}
