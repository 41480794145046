import React, {Component} from "react";


export class BoundingParameters extends Component {

	render() {
		let {bounds, count} = {...this.props};
		let mortality = bounds.mortality;
		let multiplier = bounds.multiplier;

		return (
			<div className={"p-2 w-100 bg-light"}>
				<strong>
					Bounding Parameters, applied
					to all project areas:
				</strong>
				<ol className={"list-unstyled"}>
					<React.Fragment>
						<li>
							Mortality rate: {mortality}%
						</li>
						<li>
							Planting quantity modifier: {multiplier}
						</li>
						<li>
							Original number of trees planted: {count}
						</li>
					</React.Fragment>
				</ol>
			</div>
		)
	}
}