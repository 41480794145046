import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {
	ButtonGroup,
	ToggleButton,
} from "react-bootstrap";

import {Post} from "../../classes";


export class RoadCategory extends PureComponent {
	constructor(props) {
		super(props);

		this.categories = ["A", "M"];
	}

	render() {
		return (
			<div>
				<strong className={"mr-3"}>
					Road Category:
				</strong>
				<ButtonGroup
					toggle
					className="mx-auto"
				>
					{
						this.categories.map((category) => {
							return (
								<ToggleButton
									type={"radio"}
									name={"road_category"}
									checked={this.props.category === category}
									value={category}
									key={category}
									onChange={
										() => {
											this.props.updateProject(
												{
													location: {
														category: category,
														number: "",
														type: "",
														post: new Post(),
														address: "",
													},
												}
											)
										}
									}
								>
									{category}
								</ToggleButton>
							)
						})
					}
				</ButtonGroup>
			</div>
		)
	}
}

RoadCategory.defaultProps = {
	category: "A",
};

RoadCategory.propTypes = {
	category: PropTypes.string,
	updateProject: PropTypes.func,
};
