import React, {Component} from "react";
import PropTypes from "prop-types";

import {species} from "../../constants";


export class SpeciesSelector extends Component {
	constructor(props) {
		super(props);

		this.species = species;

		this.state = {
			input: "",
			possibilities: [],
			focus: false,
		};

		this.compareValues = this.compareValues.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		if (this.props.species) {
			this.setState({
					input: this.species[this.props.species],
					focus: false,
				},
				() => this.onChange()
			)
		}

		document.addEventListener(
			"click",
			this.handleClickOutside,
			true
		);
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.species !== this.props.species) {
			let species = "";
			if (this.props.species) species = this.species[this.props.species];

			this.setState({
				input: species,
				focus: false,
			})
		}
	}

	componentWillUnmount() {
		document.removeEventListener(
			"click",
			this.handleClickOutside,
			true
		);
	}

	compareValues(key, order = "asc") {
		return function (a, b) {
			let comparison = 0;
			let first = a[key];
			let second = b[key];
			if (typeof first === "string") first = first.toLowerCase();
			if (typeof second === "string") second = second.toLowerCase();

			if (first > second) {
				comparison = 1;
			} else if (first < second) {
				comparison = -1;
			}

			return (
				(order === "desc") ? (comparison * -1) : comparison
			);
		}
	};

	onChange() {
		let input = document.getElementById(`${this.props.id}-input`);
		let value = input.value;
		let possibilities = [];
		let focus = true;

		input.classList.remove(this.props.invalidClass);

		for (let s in this.species) {
			let species = this.species[s];
			if (species.toLowerCase().includes(value.toLowerCase())) {
				possibilities.push(
					{
						code: s,
						commonName: species,
					}
				);
			}
		}

		possibilities = possibilities.sort(
			this.compareValues("commonName")
		);

		if (possibilities.length <= 0) {
			input.classList.add(this.props.invalidClass);
			focus = false;
		}

		if (
			(possibilities.length === Object.keys(this.species).length) ||
			(possibilities.length === 1)
		) {
			focus = false;
		}

		this.setState({
				input: value,
				possibilities: possibilities,
				focus: focus,
			},
			() => {
				if (possibilities.length === 1) {
					this.props.onClick(possibilities[0].code);
				}
			}
		);
	};

	onClick(item) {
		this.setState({
				input: item.commonName,
				focus: false,
			},
			() => this.props.onClick(item.code)
		);
	};

	onFocus(e) {
		e.target.select();

		if (e.currentTarget.classList.contains("is-invalid")) {
			let target = e.currentTarget;
			target.classList.remove("is-invalid");
			target.parentElement.nextSibling.classList.remove("d-block");
		}

		this.setState({
				focus: true
			},
			(e) => {
				if (this.props.onFocus) this.props.onFocus(e);
			}
		)
	};

	handleClickOutside(e) {
		if (this.node.contains(e.target)) return;

		this.setState({
			focus: false
		});
	};

	render() {
		let Tag = this.props.tag ? this.props.tag : "input";

		return (
			<React.Fragment>
				<div
					id={this.props.id}
					ref={node => this.node = node}
				>
					<Tag
						type={"text"}
						disabled={this.props.disabled}
						className={this.props.className}
						value={this.state.input}
						id={`${this.props.id}-input`}
						placeholder={this.props.placeholder}
						onChange={
							(e) => {
								this.onChange(e);
							}
						}
						onFocus={
							(e) => {
								this.onFocus(e);
							}
						}
					/>
					{this.state.focus ?
						<div
							id={`${this.props.id}-option-container`}
							className={
								(this.state.input.length >= this.props.threshold) && this.state.possibilities.length >= 1 ? "open" : null
							}
						>
							{this.state.input.length >= this.props.threshold ?
								this.state.possibilities.map((p, index) => {
									return (
										<span
											key={p.code}
											onClick={
												(e) => this.onClick(p)
											}
										>
										{p.commonName}
									</span>
									)
								})
								:
								null
							}
						</div>
						:
						null
					}
				</div>
			</React.Fragment>
		)
	}
}

SpeciesSelector.defaultProps = {
	tag: "input",
	id: "species",
	threshold: 2,
};

SpeciesSelector.propTypes = {
	className: PropTypes.string,
	invalidClass: PropTypes.string,
	id: PropTypes.string,
	placeholder: PropTypes.string,
	species: PropTypes.string,
	name: PropTypes.string,
	threshold: PropTypes.number,
	onClick: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
};
