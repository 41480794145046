import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {
	Container,
	Row,
	Col,
} from "react-bootstrap";

import {GoogleMap} from "./GoogleMap";
import {SiteTable} from "../SharedComponents/SiteTable";
import {ProjectInstructions} from "./ProjectInstructions";
import {InputModal} from "./InputModal";

import {Site} from "../../classes";

import {cloneDeep} from "lodash";
import {drawingOptions} from "../../constants";


export class MapPageContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showInputs: false,
			showBounds: false,
			activeSite: null,
		};

		this.gm = null;
		this.dm = null;
		this.drawings = {};
		this.paths = [];
		this.editing = {
			active: false,
			uuid: null,
		};

		for (let s in this.props.sites) {
			let site = {...this.props.sites[s]};
			this.paths.push({
				uuid: site.uuid,
				path: site.path,
				linear: site.linear,
			});
		}
	}

	setMap = (gm, dm, drawings) => {
		this.gm = gm;
		this.dm = dm;
		this.drawings = drawings;
	};

	onDrawingComplete = (drawing) => {
		let path = drawing.getPath();
		let coordinates = path.getArray();
		let linear = false;
		let measurement;

		if (drawing.type === "polyline") {
			linear = true;
			measurement = this.gm.geometry.spherical.computeLength(path);
		} else {
			measurement = this.gm.geometry.spherical.computeArea(path);
		}

		let site = new Site({
			uuid: drawing.uuid,
			path: coordinates,
			linear: linear,
			measurement: measurement,
		});

		this.drawings[site.uuid] = drawing;

		this.setState({
			activeSite: site,
			showInputs: true,
		});
	};

	editForm = (uuid) => {
		let site = cloneDeep(this.props.sites[uuid]);

		this.setState({
			activeSite: site,
			showInputs: true,
		});
	};

	editDrawing = (uuid, truth = false) => {
		let sites = cloneDeep(this.props.sites);
		let site = sites[uuid];
		let active;

		if (truth) {
			active = true;
			this.dm.setDrawingMode(null);
			this.drawings[uuid].setOptions(drawingOptions.active);
		} else {
			active = false;
			this.drawings[uuid].setOptions(drawingOptions.inactive);

			let path = this.drawings[uuid].getPath();
			site["path"] = [];
			path.getArray().forEach((c, i) => {
				site["path"].push({
					lat: c.lat(),
					lng: c.lng()
				});
			});

			if (site.linear) {
				this.dm.setDrawingMode("polyline");
				site["measurement"] = this.gm.geometry.spherical.computeLength(path);
			} else {
				this.dm.setDrawingMode("polygon");
				site["measurement"] = this.gm.geometry.spherical.computeArea(path);
			}

			uuid = null;
		}
		//FIXME: Biodiversity units are being calculated here because, for
		// some reason, they can't be calculated on the fly.
		// When a Development class, biodiversity-related field is changed,
		// the Development class methods don't reflect the updated value.
		// let area = site.adjustedArea() / 10000;
		// let pre = site.preDevelopment;
		// let post = site.postDevelopment;
		// site.preDevelopment.units = area * pre.distinctiveness * pre.condition * pre.connectivity * pre.significance;
		// site.postDevelopment.units = area * post.distinctiveness * post.condition * post.connectivity * post.significance * post.difficulty * post.duration * post.risk;

		this.editing.active = active;
		this.editing.uuid = uuid;

		if (!uuid && !active) {
			this.props.updateProject({
				sites: sites
			});
		}
	};

	updateSite = (field, value) => {
		let site = cloneDeep(this.state.activeSite);
		site[field] = value;

		this.setState({
			activeSite: site,
		});
	};

	completeSite = (state) => {
		let sites = cloneDeep(this.props.sites);
		let site = cloneDeep(this.state.activeSite);

		for (let field in state) if (field in site) site[field] = state[field];

		//FIXME: Biodiversity units are being calculated here because, for
		// some reason, they can't be calculated on the fly.
		// When a Development class, biodiversity-related field is changed,
		// the Development class methods don't reflect the updated value.
		// let area = site.adjustedArea() / 10000;
		// let pre = site.preDevelopment;
		// let post = site.postDevelopment;
		// site.preDevelopment.units = area * pre.distinctiveness * pre.condition * pre.connectivity * pre.significance;
		// site.postDevelopment.units = area * post.distinctiveness * post.condition * post.connectivity * post.significance * post.difficulty * post.duration * post.risk;

		site.complete = true;
		sites[site.uuid] = site;

		this.setState({
				showInputs: false,
				activeSite: site,
			},
			() => {
				this.props.updateProject({
					sites: sites
				});
			}
		);
	};

	removeSite = (uuid = null) => {
		let site = cloneDeep(this.state.activeSite);
		let sites = cloneDeep(this.props.sites);
		if (!uuid) uuid = site.uuid;

		delete sites[uuid];

		if (uuid in this.drawings) {
			let drawing = this.drawings[uuid];
			drawing.setMap(null);
			drawing = null;
			delete this.drawings[uuid];
		}

		this.setState({
				activeSite: null,
				showInputs: false,
			},
			() => {
				this.props.updateProject(
					{sites: sites},
					false
				);
			}
		);
	};

	toggleHighlight = (uuid, truth) => {
		if (!this.editing.active) {
			let drawing = this.drawings[uuid];

			// Check for drawing to prevent errors when hovering
			// over the table before the drawings have been redrawn.
			if (drawing) {
				if (truth) {
					drawing.setOptions(drawingOptions.hover);
				} else {
					if (this.editing === uuid) {
						drawing.setOptions(drawingOptions.active);
					} else {
						drawing.setOptions(drawingOptions.inactive);
					}
				}
			}
		}
	};

	hideModal = (field = "showInputs") => {
		let site = cloneDeep(this.state.activeSite);
		if (site.complete) {
			this.setState({
				[field]: false,
			});
		} else {
			let drawing = this.drawings[site.uuid];
			drawing.setMap(null);
			delete this.drawings[site.uuid];

			this.setState({
				site: null,
				[field]: false,
			});
		}
	};

	onDragEnd = (center) => {
		this.props.updateProject({
			location: {
				center: center,
			},
		});
	};

	onZoomChange = (zoom) => {
		this.props.updateProject({
			location: {
				zoom: zoom,
			},
		});
	};

	render() {
		return (
			<Container fluid>
				<Row>
					<Col xl={{span: 8}}>
						<Row>
							<Col>
								<GoogleMap
									zoom={this.props.zoom}
									center={this.props.center}
									markerPosition={this.props.markerPosition}
									drawingControl={true}
									drawingMode={"polygon"}
									libraries={["drawing", "geometry"]}
									rotateControl={false}
									tilt={0}
									type={"satellite"}
									paths={this.paths}
									callback={this.setMap}
									onDragEnd={this.onDragEnd}
									onZoomChange={this.onZoomChange}
									onPolygonComplete={this.onDrawingComplete}
									onPolylineComplete={this.onDrawingComplete}
								/>
							</Col>
						</Row>
						<Row className={"mt-3"}>
							<Col>
								<SiteTable
									bounds={this.props.bounds}
									project={true}
									sites={this.props.sites}
									editForm={this.editForm}
									toggleHighlight={this.toggleHighlight}
									hideModal={this.hideModal}
									removeSite={this.removeSite}
									editDrawing={this.editDrawing}
								/>
							</Col>
						</Row>
					</Col>
					<Col xl={{span: 4}}>
						<ProjectInstructions
							length={Object.keys(this.props.sites).length}
							bounds={this.props.bounds}
							updateProject={this.props.updateProject}
						/>
					</Col>
				</Row>
				{this.state.showInputs ?
					<InputModal
						show={this.state.showInputs}
						site={this.state.activeSite}
						completeSite={this.completeSite}
						updateSite={this.updateSite}
						cancelSite={this.removeSite}
						hideModal={this.hideModal}
					/>
					:
					null
				}
			</Container>
		)
	}
}

MapPageContainer.propTypes = {
	condition: PropTypes.number,
	distinctiveness: PropTypes.number,
	mortality: PropTypes.number,
	quantity: PropTypes.number,
	sites: PropTypes.object,
	updateProject: PropTypes.func,
};
