import React, {PureComponent} from "react";

import "../../../styles/habitat-selector.scss";
import {
	Col,
	Form,
	Row
} from "react-bootstrap";

import {Habitat} from "../../../classes";

import {habitats} from "../../../constants";


export class HabitatSelector extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			focus: false,
			width: 466,
		};

		this.habitats = habitats;
	}

	componentDidMount() {
		document.addEventListener(
			"click",
			this.handleClickOutside,
			true
		);
	}

	componentWillUnmount() {
		document.removeEventListener(
			"click",
			this.handleClickOutside,
			true
		);
	}

	handleClickOutside = (e) => {
		if (this.node.contains(e.target)) return;

		this.setState({
			focus: false
		})
	};

	onChange = (e) => {
		console.log("Habitat onChange doesn't do anything currently.");
	};

	onClick = (e) => {
		let development = this.props.postDevelopment ? "postHabitat" : "preHabitat";
		let target = e.currentTarget;
		let habitat = new Habitat();

		for (let field in target.dataset) {
			habitat[field] = target.dataset[field];
		}

		habitat.grassland = habitat.grassland.toString() === "true";
		habitat.hardstanding = habitat.hardstanding.toString() === "true";

		this.props.updateSite(
			development,
			habitat
		);

		this.setState({
			focus: false,
		});
	};

	toggleFocus = (focused) => {
		let el = document.getElementById(`${this.props.id}`);
		let input = document.getElementById(`${this.props.id}-input`);

		if (input.classList.contains("is-invalid")) {
			input.classList.remove("is-invalid");

			el.nextSibling.classList.remove("d-block");
		}

		this.setState({
			focus: focused,
			width: el.offsetWidth,
		});
	};

	render() {
		return (
			<Row>
				<Col>
					<Form.Group controlId={`${this.props.id}Selector`}>
						<Form.Label>
							{this.props.label}
						</Form.Label>
						<div
							id={this.props.id}
							ref={node => this.node = node}
						>
							<input
								className={"form-control habitat-selector"}
								value={this.props.habitat ? this.props.habitat.envis : ""}
								id={`${this.props.id}-input`}
								placeholder={this.props.placeholder}
								onChange={
									(e) => {
										this.onChange(e)
									}
								}
								onFocus={
									(e) => {
										this.toggleFocus(true)
									}
								}
							/>
							{this.state.focus ?
								<div
									id={`${this.props.id}-option-container`}
									className={this.state.focus ? "d-block open" : "d-none"}
									style={{width: this.state.width}}
								>
									{this.habitats.map(
										(option, idx) => {
											return (
												Object.entries(option.names).map((name, idxt) => {
														return (
															<span
																data-code={name[0]}
																data-envis={name[1].envis}
																data-nvc={name[1].nvc}
																data-total={option.total}
																data-runoff={option.runoff}
																data-pollution={option.pollution}
																data-storage={option.carbon.storage}
																data-sequestration={option.carbon.sequestration}
																data-grassland={option.grassland}
																data-hardstanding={option.hardstanding}
																data-group={option.group}
																key={`${idx}${idxt}`}
																style={{width: this.state.width}}
																onClick={(e) => this.onClick(e)}
															>
											<p className={"mb-0"}>
												{name[1].envis}
											</p>
											<small className={"mt-n5 font-italic"}>
												{name[1].nvc}
											</small>
										</span>
														)
													}
												)
											)
										})
									}
								</div>
								:
								null
							}
						</div>
						<Form.Control.Feedback type={"invalid"}>
							Please select a habitat type.
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
		)
	}
}

HabitatSelector.defaultProps = {
	id: "habitatSelector",
};
