import React, {Component} from 'react';
import PropTypes from "prop-types";

import {
	Container,
	Row,
	Col,
} from "react-bootstrap";

import {Introduction} from "./Intro";
import {Logos} from "./Logos";
import {BrowserWarning} from "./BrowserWarning";
import {Overview} from "./Overview";
import {LoadProject} from "./LoadProject";
import {NewProject} from "./NewProject";


export class LandingPage extends Component {

	render() {
		return (
			<Container fluid>
				<Row>
					<Col
						md={{span: 10, offset: 1}}
						lg={{span: 7, offset: 0}}
						xl={{offset: 1}}
					>
						<Introduction/>
						<Overview/>
						<Logos/>
						<BrowserWarning
							appName={"the Ecosystem Break-Even Calculator"}
							className={"d-none d-md-block mx-auto small mt-5 mb-n5 text-justify"}
							browserList={"Chrome, Firefox, Edge, and Safari"}
						/>
					</Col>
					<Col>
						{window.location.hostname === "localhost" ?
							<LoadProject
								loadProject={this.props.loadProject}
							/>
							:
							null
						}
						<NewProject
							history={this.props.history}
							location={this.props.location}
							updateProject={this.props.updateProject}
						/>
					</Col>
				</Row>
			</Container>
		);
	}
}

LandingPage.propTypes = {
	project: PropTypes.object
};
