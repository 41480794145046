import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {Post} from "../../classes";


export class LocationType extends PureComponent {
	constructor(props) {
		super(props);

		this.types = [
			{
				abbr: "TB",
				full: "Emergency Telephone",
			},
			{
				abbr: "MP",
				full: "Marker Post",
			},
			{
				abbr: "BO",
				full: "Overbridge",
			},
		];
	}

	render() {
		return (
			<div>
				<strong>Location Type</strong>
				<select
					id={"location-types-selector"}
					name={"location-types"}
					className={"form-control"}
					required
					value={this.props.type}
					onChange={
						(e) => {
							this.props.updateProject(
								{
									location: {
										type: e.currentTarget.value,
										address: "",
										post: new Post(),
									},
								}
							)
						}
					}
				>
					<option
						disabled
						value={""}
					>
						Select a Location Type
					</option>
					{this.types.map(
						(type, idx) => {
							return (
								<option
									key={type.full}
									value={type.abbr}
								>
									{type.full}
								</option>
							)
						}
					)}
				</select>
			</div>
		)
	}
}

LocationType.defaultProps = {
	type: "",
};

LocationType.propTypes = {
	type: PropTypes.string,
	updateProject: PropTypes.func,
};
