import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {
	Form,
	Row,
	Col,
} from "react-bootstrap";


export class Width extends PureComponent {

	render() {
		return (
			<Row>
				<Col>
					<Form.Group controlId="projectRevision">
						<Form.Label>
							Linear Development Width
						</Form.Label>
						<Form.Control
							type={"number"}
							value={this.props.width}
							step={"1"}
							min={"1"}
							max={"100"}
							onChange={
								(e) => {
									this.props.updateSite(
										"width",
										parseInt(e.currentTarget.value)
									);
								}
							}
							onFocus={
								(e) => {
									e.currentTarget.select();
									this.props.resetValidity(e);
								}
							}
						/>
						<Form.Control.Feedback type={"invalid"}>
							Please enter a number from 1 to 100.
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
		)
	}
}

Width.defaultProps = {
	width: 0,
};

Width.propTypes = {
	width: 0,
	updateSite: PropTypes.func,
	resetValidity: PropTypes.func,
};
