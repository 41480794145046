import React, {PureComponent} from "react";

import PropTypes from "prop-types";

import Plotly from "../../classes/custom-plotly"
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);


export class BreakEvenPlot extends PureComponent {

	render() {
		let {currentBenefits, forecastBenefits} = {...this.props};
		let plotType = "scatter";
		let lineType = "dash";
		let ratio = 1.61803398875;
		let width = window.innerWidth;
		if (width >= 1024) width = width * .625;
		let height = width / ratio;
		if (height > 500) height = 500;
		let colors = {
			gn: "green",
			bk: "black",
			gy: "gray",
			be: "blue",
			oe: "orange",
		};
		let config = {
			displaylogo: false,
			responsive: true,
			modeBarButtons: [
				[
					"zoom2d",
					"pan2d",
					"resetScale2d",
				]
			],
		};
		let data = [
			{
				y: forecastBenefits.total,
				type: plotType,
				marker: {color: colors.gn},
				name: "TOTAL FORECAST VALUATION",
				legendgroup: "group1",
			},
			{
				y: currentBenefits.total,
				type: plotType,
				marker: {color: colors.bk},
				line: {
					dash: lineType,
				},
				name: "TOTAL CURRENT VALUATION",
				legendgroup: "group1",
			},
			{
				y: forecastBenefits.sequestration,
				type: plotType,
				marker: {color: colors.gy},
				name: "Forecast CO2 Sequestration",
				visible: "legendonly",
				legendgroup: "group2",
			},
			{
				y: currentBenefits.sequestration,
				type: plotType,
				marker: {color: colors.gy},
				line: {
					dash: lineType,
				},
				name: "Current CO2 Sequestration",
				visible: "legendonly",
				legendgroup: "group2",
			},
			{
				y: forecastBenefits.runoff,
				type: plotType,
				marker: {color: colors.be},
				name: "Forecast Runoff Avoided",
				visible: "legendonly",
				legendgroup: "group3",
			},
			{
				y: currentBenefits.runoff,
				type: plotType,
				marker: {color: colors.be},
				line: {
					dash: lineType,
				},
				name: "Current Runoff Avoided",
				visible: "legendonly",
				legendgroup: "group3",
			},
			{
				y: forecastBenefits.pollution,
				type: plotType,
				marker: {color: colors.oe},
				name: "Forecast Pollution Removal",
				visible: "legendonly",
				legendgroup: "group4",
			},
			{
				y: currentBenefits.pollution,
				type: plotType,
				marker: {color: colors.oe},
				line: {
					dash: lineType,
				},
				name: "Current Pollution Removal",
				visible: "legendonly",
				legendgroup: "group4",
			},
		];

		return (
			<React.Fragment>
				<Plot
					data={data}
					config={config}
					layout={
						{
							width: width,
							height: height,
							xaxis: {
								title: this.props.xAxisTitle,
								range: this.props.range,
							},
							yaxis: {
								title: {
									text: this.props.yAxisTitle,
									standoff: 15,
								},
							},
							margin: {
								t: 15,
								r: 80,
								b: 160,
								l: 100,
								autoexpand: false,
							},
							legend: {
								orientation: "h",
								y: -0.1875,
							}
						}
					}
					useResizeHandler={this.props.useResizeHandler}
				/>
			</React.Fragment>
		)
	}
}

BreakEvenPlot.defaultProps = {
	xAxisTitle: "Years",
	yAxisTitle: "Value in &#163;",
	useResizeHandler: true,
	range: [0, 99],
};

BreakEvenPlot.propTypes = {
	xAxisTitle: PropTypes.string,
	yAxisTitle: PropTypes.string,
	useResizeHandler: PropTypes.bool,
	range: PropTypes.array,
	tracings: PropTypes.object,
	multiplier: PropTypes.number,
	mortality: PropTypes.number,
};
