import React, {Component} from "react";
import {Modal} from "react-bootstrap";


export class LoadingModal extends Component {

	render() {
		return (
			<Modal
				show={this.props.loading}
				onHide={
					(e) => this.props.updateProject({loading: false})
				}
			>
				<Modal.Body>
					<h3>Loading</h3>
				</Modal.Body>
			</Modal>
		)
	}
}

LoadingModal.defaultProps = {
	loading: false,
};